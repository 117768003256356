import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { reducer as toastr } from 'react-redux-toastr'
import { routerReducer as routing } from 'react-router-redux'

import ParticipantGroupAws from './ParticipantGroupAws'
import Settings from './Settings';

const compiler = combineReducers({
    form,
    toastr,
    routing,
    ParticipantGroupAws,
    Settings,
})

export default compiler