const TagTeamInitialState = { 
    participant_group_id: 0,
    players             : [],
    modalVisible        : false,
    sideMenu            : false,
    filter              : false,
    previewWidth        : window.innerWidth, 
    isInitial           : false,
    togglePlayerModal   : false,
    currentParticipant  : null,
    currentFirstName    : null,
    currentLastName     : null,
    currentBox          : 0,
    currentFile         : 0,
    currentUrl          : '',
    showInstructions    : false,
    zoomInPlayer        : false,
    _data               : false,
    type                : '1',
    dimensionWidth      : 0
}

export default TagTeamInitialState