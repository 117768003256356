const Participant = {
    fields: () => ({
            id: 0,
            first_name: null,
            last_name: null,
            participant_group_id: 0,
            status_id: 1,
            type: 1
        }),
   	
   	setStatus: (status_id) => ({ status_id: status_id }),
   	getStatus: (participant) => participant.status_id,

    create: (participantGroupId) => `teams/${participantGroupId}/players/allow-create`,
    update: (participantGroupId, participantId) => `teams/${participantGroupId}/players/${participantId}/allow-update`,
    absent: (participantId) => `players/${participantId}/attendance/absent`,
    archive: (participantId) => `players/${participantId}/attendance/archive`,
    present: (participantId) => `players/${participantId}/attendance/present`,
}

export default Participant