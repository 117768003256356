import _ from 'lodash'
import ParticipantStatus from './ParticipantStatus'

let currentBox = 0

const ParticipantGroupAws = {
    transform: (data, callback) => {
    	let faces = []
    	if (data.participant_faces) {
	    	callback( {
                id: (data.id) ? data.id : 0,
                team_name: (data.name) ? data.name : null,
                is_archived: data.archived_at ? true : false,
                event_hash_id: data.event_hash_id || 0,
	    		participants: (data.participants_with_faces) ? ParticipantGroupAws.transformParticipants(data.participants_with_faces) : [],
	    		faces: ParticipantGroupAws.transformFaces(data.participant_faces),
                url: ParticipantGroupAws.transformFileURL(data.participant_faces, true),
                main_file: ((data.participant_faces && data.participant_faces[0]) && (data.participant_faces[0].image && data.participant_faces[0].image[0])) ? data.participant_faces[0].image[0].file_id : 0,
                last_face: ParticipantGroupAws.getLastFace(data.participant_faces)
            })
    	}

    },

    getLastFace: (faces) => {
        let lastFace = null
        let control = 0
        faces.forEach(function(face){
            control += 1
            if (face.participant_id !== null) {
                lastFace = (lastFace && lastFace.updated_at > face.updated_at) ? lastFace : face
            }
        })

        if (control == faces.length)
            return lastFace
    },

    transformParticipants: (participants) => {
        let presentCount = 0
        let insertCount = 0
        let statusOrder = [1, 3, 5, 4, 0]
        let players = _.sortBy(participants, function(o) { return statusOrder.indexOf(o.status_id); })
        let _participants = players.map(function(p){
            let attendance = ParticipantStatus.transform(p.status_id)
            if (p.face && p.face.length == 0 && attendance == 'PRESENT') {
                presentCount++
            }

            if (p.face && p.face.length == 0 && attendance == 'INSERT') {
                insertCount++
            }

            p['face'] = (p.face && p.face.length > 0) ? ParticipantGroupAws.transformFaces(p.face) : []
            p['status'] = attendance
            return p
        })

        return { data: _participants, active_with_no_face: presentCount, insert_with_no_face: insertCount }
    },

    transformFaces: (_faces) => {
        let ctr = 0
        let active = 0
    	let allFaces = _faces.map(function(face){
    		let faces = {
                id: face.id,
                file_id: face.file_id,
                aws_bb_height: face.aws_bb_height,
                aws_bb_width: face.aws_bb_width,
                aws_bb_left: face.aws_bb_left,
                aws_bb_top: face.aws_bb_top,
                url: null,
                face_url: face.face_url,
                participant_full_name: (face.participant) ? `${face.participant.first_name} ${face.participant.last_name}` : null,
                is_tagged: (face.participant_id !== null && face.participant_id > 0),
                is_archived: (face.participant_id === 0),
                participant_id: face.participant_id,
                updated_at: face.updated_at,
                use_sprite: (face.face_url === null)
            }

            if (face.participant_id == null) {
                ctr++
            }

            if (face.participant_id == null && active == 0) {
                active = face.id
            }

            if (face.image && face.image[0] ) {
                faces['file'] = face.image[0].file
                if (faces.url = ParticipantGroupAws.transformFileURL(face.image[0])) {
                    return faces
                }
            }
        })

        return { all_faces: allFaces, remaining: ctr, active: active, count: _faces.length }
    },

    transformFileURL: (image, main=false) => {
        if (main && (image && image[0]) && (image[0].image && image[0].image[0])) {
            image = image[0].image[0]
        }

    	const { file_s3, scaled_file_path, wm_file_path } = image

        if (file_s3 && (scaled_file_path || wm_file_path)) {
    		let s3 = 's3'
    		let region = (s3.indexOf(file_s3.s3_region) !== -1) ? `s3.${region}` : file_s3.s3_region
            let file_path = (wm_file_path) ? wm_file_path : scaled_file_path

    		return `http://${file_s3.s3_bucket}.s3.amazonaws.com/${file_path.replace(/ /g,"%20")}`
        }
    }
}

export default ParticipantGroupAws