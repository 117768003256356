import React, { Component } from 'react'
import { View, Text, StyleSheet } from 'react-native'

class InvalidHashScene extends Component {
    render() {
    	return (
    		<View style={styles.app}>
    			<View style={styles.appHeader}>
    				<Text style={styles.appTitle}> Are you having issues visiting the page? </Text><br />
    				<Text style={styles.appTitle}> Please contact customer service at </Text>
    				<Text style={styles.appTitle}><a href="mailto:customerservice@sportsinfocus.com.au">customerservice@sportsinfocus.com.au</a></Text>
    			</View>
    		</View>
    	)
    }
}

const styles = StyleSheet.create({
	app: { flex: 1 },
	appHeader: {
		flex: 1,
		padding: 20,
		alignItems: 'center',
		backgroundColor: '#222',
		justifyContent: 'center'
	},
	appTitle: {
		fontSize: 16,
		color: 'white'
	},
	appIntro: {
		flex: 2,
		fontSize: 30,
		textAlign: 'center'
	}
})

export default InvalidHashScene;