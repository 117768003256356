const ParticipantGroupAws = {
    fields: () => ({
            id: 0,
            aws_collection_id: null,
            participant_group_id: 0
        }),
    
    getAll: (hash)     => `participant-group-faces?hash=${hash}`,
   	signOff: (id)      => `participant-group/${id}/tag-sign-off`,
   	sendFeedback: (id) => `participant-group/${id}/tag-feedback`,

   	postFreshdeskTicket: () => `http://beta.sportsinfocus.com.au/sponsor-form/store-salesforce/services/freshdesk/sendTicket.php`
}

export default ParticipantGroupAws