import React, { Component } from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import Swipeout from 'rc-swipeout'

class SwipeOutList extends Component {
	constructor(props) {
		super(props)
		this.onOpen = this.onOpen.bind(this)
		this.onClose = this.onClose.bind(this)
		this.handleClick = this.handleClick.bind(this)
		this.handleRightSlide = this.handleRightSlide.bind(this)
	}

	onOpen() {
		return this.props.onOpen()
	}

	onClose() {
		return this.props.onClose()
	}

	handleClick(e) {
		e.preventDefault()
		if (e.cancelable) {
			return this.props.handleClick()
		}

		console.log("Swiping...")
	}

	closeRightSlide(ref, e) {
		e.preventDefault()

		if (ref) {
			if (window.innerWidth > 300) {
				this.refs[ref].right.style.zIndex = 0
			}

			return this.refs[ref].close()
		}

		console.log("ref not valid")
	}

	handleRightSlide(ref, e) {
		e.preventDefault()
		
		if (ref && window.innerWidth > 300) {
			this.refs[ref].right.style.zIndex = 2
			return this.props.onOpen()
		} else if (ref) {
			return this.refs[ref].doOpenRight()
		}

		console.log("ref not valid")
	}

	render() {
		const { _ref, customStyle, swipeTriggerStyle, status } = this.props

		let currentStyle = {
			backgroundColor: 'white', 
	        cursor: 'pointer', 
	        borderBottom: '1px solid gray'
		}

		let _customStyle = {}
			_customStyle = (status == 'INSERT') ? { borderLeft: '5px solid orange' } : _customStyle

		let style = Object.assign({}, currentStyle, _customStyle)
		let triggerStyle = (swipeTriggerStyle) ? swipeTriggerStyle : styles.swipeTrigger

	    return ( 
	    	<View onMouseEnter={(e) => this.handleRightSlide(_ref, e)} onMouseLeave={(e) => this.closeRightSlide(_ref, e)} className="swipe-out-container">
				<Swipeout 
					autoClose={true}
					style={style}
					left={this.props.leftBtns}
					right={this.props.btns}
					onOpen={this.onOpen}
					onClose={this.onClose}
					ref={_ref}
				>
					
					<Text>

						<TouchableOpacity 
							style={styles.list} 
							className="swipe-list" 
							onPress={this.handleClick}
						>
							<Text>{ this.props.title }</Text>
						</TouchableOpacity>

						<TouchableOpacity style={triggerStyle} className="swipe-trigger" onPress={(e) => this.handleRightSlide(_ref, e)}>
							<Text><i className={this.props.icon} style={{ float: 'right' }}></i></Text>
						</TouchableOpacity>
						
					</Text>

				</Swipeout>
			</View> 
		)
	}
}

const styles = StyleSheet.create({
    list: {
        padding: '20px', 
        textAlign: 'left',
        width: '70%'
    },
    swipeTrigger: {
    	padding: '20px',
    	float: 'right',
    	width: '30%',
    }
})

export default SwipeOutList;