const ParticipantStatus = {
    transform: (status) => {
    	switch(status) {
            case 1: 
                return 'PRESENT'
            case 4:
                return 'INSERT'
            case 5: 
                return 'ABSENT'
            default:
                return 'REMOVED FROM TEAM LIST'
        }
    }
}

export default ParticipantStatus