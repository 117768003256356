import {
	PARTICIPANT_GROUP_AWS_REQUEST,
	PARTICIPANT_GROUP_AWS_SUCCESS,
	PARTICIPANT_GROUP_AWS_FAILED
} from '../Handlers/ParticipantGroupAws';

const ParticipantGroupAws = (
	state = {
		isFetching: false,
		invalid: false,
		data: [],
	}, action) => {
	switch(action.type) {
		case PARTICIPANT_GROUP_AWS_REQUEST:
			return {
				...state,
				isFetching: true,
				invalid: false,
				data: []
			}
		case PARTICIPANT_GROUP_AWS_SUCCESS:
			return {
				...state,
				isFetching: false,
				invalid: false,
				data: action.data
			}
		case PARTICIPANT_GROUP_AWS_FAILED:
			return {
				...state,
				isFetching: false,
				invalid: true,
				data: []
			}
		default:
			return state
	}
}

export default ParticipantGroupAws;