const ParticipantType = {
    transform: (type) => {
    	return [
		    "Coach",
		    "Asst. Coach",
		    "Co-Coach",
		    "Team Manager",
		    "Asst. Team Manager",
		    "Manager",
		    "Asst. Manager",
		    "Official",
		    "Runner",
		    "Trainer",
		    "Physio",
		    "President",
		    "Secretary",
		    "Treasurer",
		    "Parent"
    	]
    }
}

export default ParticipantType