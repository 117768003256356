import React, { Component } from 'react'
import { browserHistory }   from 'react-router'
import RenderIf             from 'render-if'
import { View, Text, StyleSheet, ScrollView } from 'react-native'

class TagTeam extends Component {
	constructor(props) {
		super(props)
	}

    render() {
    	let faqs = [
    		{
    			question: "How Do I Identify Participants?",
    			answer: "Match the face in the top left to the matching name in the list. Just click on the name to create the match, and the next face will be shown. Don't see the individual listed? Just click to manually add the player record."
    		},
    		{
    			question: "What If I Identify The Wrong Person?",
    			answer: "Just continue on. When you get to the end, you can undo any mistakes and correct the effected participants."
    		},
    		{
    			question: "I've Identified All Faces, But There Are Still Names?",
    			answer: "In the case that there are more names then faces, then the participants were most likely absent. Once you finish identifying all faces, either mark them as absent, or remove them if they are not part of your team."
    		},
    		{
    			question: "I've Identified All Names, But There Are Still Faces?",
    			answer: "In the case, we were not given the complete list of all participants, then you can add them by clicking \"+ Add Player\". If they are not a player, please select the most appropriate title."
    		},
    		{
    			question: "What Does Remove Do?",
    			answer: "When you swipe across, you will see REMOVE. This tells us this player is not part of this team, and archives them. Don't worry, it doesn't delete the data completely, and it can be undone."
    		},
    		{
    			question: "The Participants Spelling Is Wrong?",
    			answer: "Just swipe across and EDIT the participant details."
    		},
    		{
    			question: "I Can't See The Face Properly?",
    			answer: "You can click on the face to see a more zoomed image, or click on the image icon to see the full team shot. The current players face will be highlighted in Red."
    		},
    		{
    			question: "This Is Not My Team?",
    			answer: "Please contact our support team on 1800 88 776 787, simply reply to the email you received, and we'll get back to you as soon as possible to resolve any issues."
    		}
    	]
 
    	return (
			<View style={styles.app}>
				<ScrollView>
                    <div style={{borderBottom: '1px solid #bdb7b7', padding: '12px', textAlign: 'left', marginLeft: '10px', color: '#738fa0'}}>
                        Welcome! The revolutionary method of matching player data to player photos in just minutes. 
                        Thank you for taking the time to complete this critical step. 
                        Your contribution helps ensure the timely and accurate delivery of your teams photos. 
                        If you have questions, checkout the Q&A below, or feel free to contact our service team directly.
                    </div>
					{faqs.map(function(faq){
						return (<View style={{ borderBottom: '1px solid #bdb7b7', padding: '12px', textAlign: 'left' }}>
							<div style={{ color: 'orange', fontSize: '11pt', fontWeight: '500' }}>{faq.question}</div>
							<div style={{ marginLeft: '10px', color: '#738fa0' }}>{faq.answer}</div>
						</View>)
					})}
				</ScrollView>
			</View>
    	)
    }
}

const styles = StyleSheet.create({
	app: {
		flex: 1,
		height: '75vh'
	}
})

export default TagTeam