import React, { Component }              from 'react';
import { connect }                       from 'react-redux'
import { Router, Route, browserHistory } from 'react-router'
import PropTypes                         from 'prop-types'
import ReactTooltip                      from 'react-tooltip'

import MainContainer from '../Components/Containers/index';
import MainScene     from '../Scenes/index';
import TeamScene     from '../Scenes/Team/index';
import AddPlayerScene from '../Scenes/Player/add';
import TeamSignOff   from '../Scenes/Team/Signoff';
import TeamFeedback  from '../Scenes/Team/Feedback';
import ThankYouScene from '../Scenes/Team/ThankYou'
import InvalidHash   from '../Scenes/Team/InvalidHash';

const propTypes = {}

class MainRoute extends Component {
    render() {
        return (
            <div id="main-router">
                <ReactTooltip />
                <Router history={browserHistory} path='/' component={MainContainer}>
                    <Route name="Main" path="/" component={MainScene}/>
	                <Route name="Team Tagging" path="/" component={ TeamScene }>
                        <Route name=":key" path=":key" component={ TeamScene } params={{key: ':key'}} />
                    </Route>
                    <Route path="/" name="Main">
                        <Route path=":key" name="Team Tagging">
                            <Route path="invalid" name="InvalidHash" component={InvalidHash} params={{key: ':key'}} />
                            <Route path="new" name="NewPlayer" component={AddPlayerScene} params={{key: ':key'}} />
                            <Route path="signoff" name="TeamSignOff" component={TeamSignOff} params={{key: ':key'}} />
                            <Route path="feedback" name="Feedback" component={TeamFeedback} params={{key: ':key'}} />
                            <Route path="thank-you" name="ThankYou" component={ThankYouScene} params={{key: ':key'}} />
                        </Route>
                    </Route>
                </Router>
            </div>
        )
    }
}

MainRoute.propTypes = {
    dispatch: PropTypes.func.isRequired
}

function mapStateToProps(state) { }

export default connect(mapStateToProps)(MainRoute)