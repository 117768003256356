import React, { Component } from 'react'

class ExtraLargeButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: true
        }
        this.handleClick = this.handleClick.bind(this)
    }
    componentWillReceiveProps(props) {
        this.setState({ active: true })
    }
    handleClick() {
        if (this.state.active) {
            this.props.action()
            this.setState({ active: false })
        }
    }
    render() {
        return ( <button style={{
                height: (this.props.height) ? this.props.height : '75px', 
                width: (this.props.width) ? this.props.width :'inherit',
                backgroundColor: (this.state.active) ? '#F2D608' : '#f7f776',
                color: '#00529d',
                border: '#dbc20e',
                marginLeft: (this.props.marginLeft) ? this.props.marginLeft : '100px',
                fontWeight: 700,
                display: 'inline-block',
                'line-height': '15px',
                right: 0,
                fontSize: '14pt',
                borderRadius: '5px',
                float: (this.props.float) ? this.props.float : ''
            }} onClick={this.handleClick}> 
                {(this.props.icon) ? React.createElement('i', {className: this.props.icon}) : ''} 
                {(this.state.active) ? this.props.title : '...'}
                {(this.props.description) ? React.createElement('button', {style: {fontSize: '50%', backgroundColor: 'transparent', border: 'none'}}, this.props.description) : ''} 
            </button> )
    }
}

export default ExtraLargeButton;