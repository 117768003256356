import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import ReduxToastr from 'react-redux-toastr'
import { createStore, applyMiddleware } from 'redux'
import { Router, Route, browserHistory } from 'react-router'
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux'

import compiler from './Services/Reducers/index'
import MainRoute from './Routes/index'
// import App from './Scenes/index'

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

const middleware = [ thunkMiddleware, routerMiddleware(browserHistory) ].filter(Boolean)

let createStoreWithMiddleware = applyMiddleware(...middleware)(createStore)

let store = createStoreWithMiddleware(
	compiler,
	/**
	* For Redux Devtools Extension
	* https://github.com/zalmoxisus/redux-devtools-extension#1-for-chrome
	*/
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

const history = syncHistoryWithStore(browserHistory, store)

ReactDOM.render(
	<Provider store={store}>
        <div>
			<Router history={history} path='/' component={MainRoute}>
				<Route path="*" name="Router" component={MainRoute}/>
			</Router>
			<ReduxToastr
				timeOut={10000}
				position="bottom-right"
				transitionIn="fadeIn"
				transitionOut="fadeOut"
				progressBar
				closeOnToastrClick
			/>
        </div>
    </Provider>, document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()