import ParticipantAws from '../Handlers/ParticipantAws'
import Calendar       from '../Helpers/Calendar'
import { toastr }     from 'react-redux-toastr'
import _              from 'lodash'

const TagUnTagReactionTransformer = {
    tag: (params, dispatch, ctr, nextBox, currentData, currentBox, player, faceId, callback) => {
    	if (currentData.faces.remaining > 0) {
            dispatch( ParticipantAws.assign({ id: player.id, faceId: currentBox, hash: params.key }) )

            currentData.faces.all_faces = currentData.faces.all_faces.map(function(face){
                if (!face.is_tagged) {
                    if (ctr == 0 && face.id == currentBox) {
                        ctr = face.id

                        currentData.last_face = face
                        currentData.participants.data = currentData.participants.data.map(function(participant) {
                            if (participant.id == player.id) {

                                // deduct from participant counter
                                if (participant.status == 'PRESENT' && !face.is_archived) {
                                    currentData.participants.active_with_no_face -= 1
                                }

                                if (participant.status == 'INSERT' && !face.is_archived) {
                                    currentData.participants.insert_with_no_face -= 1
                                }
                                
                                let _participant = participant
                                    _participant.face = {'all_faces': [face]}

                                return _participant
                            }

                            return participant
                        })

                        // deduct from face counter
                        currentData.faces.remaining -= 1

                        let _face = face
                            _face.participant_id = player.id
                            _face.is_tagged = (player.id > 0)
                            _face.is_archived = (player.id === 0)
                            _face.updated_at  = Calendar.now()
                            _face.participant_full_name = `${player.first_name} ${player.last_name}`
                        
                        return _face
                    
                    } else if (nextBox == 0 && !face.is_archived) {
                        nextBox = face.id
                        currentData.faces.active = face.id
                    }
                }

                return face
            })
        } else {
            currentData.faces.active = 0
        }

        callback(currentData)
    },

    unTag: (params, dispatch, faceId, currentData, callback) => {
        dispatch( ParticipantAws.unAssign({ id: faceId, hash: params.key }) )

        let lastFace    = currentData.last_face
        let ctr         = 0 
        let counter     = 0

        currentData.faces.all_faces = currentData.faces.all_faces.map(function(face){
            counter += 1

            if (ctr == 0 && face.id == faceId) {
                ctr = face.id
                if (face.participant_id != 0) {
                    currentData.participants.data = currentData.participants.data.map(function(participant) {
                        if (face.participant_id == participant.id) {
                            // deduct from participant counter
                            if (participant.status == 'PRESENT') {
                                currentData.participants.active_with_no_face += 1
                            }

                            if (participant.status == 'INSERT') {
                                currentData.participants.insert_with_no_face += 1
                            }
                            
                            let _participant = participant
                                _participant.face = null

                            return _participant
                        }

                        return participant
                    })
                }

                currentData.faces.remaining += 1
                let _face = face
                    _face.participant_id = null
                    _face.is_tagged = false
                    _face.is_archived = false

                currentData.faces.active = face.id
                
                return _face
            }

            if (face.is_tagged || face.is_archived) {
                lastFace = (lastFace && lastFace.updated_at > face.updated_at) ? lastFace : face
            }

            return face
        })

        if (counter == currentData.faces.all_faces.length) {
            currentData.last_face = lastFace
            callback(currentData)
        }

        callback(currentData)
    },

    undo: (dispatch, params, currentData, callback) => {
    	if (currentData.last_face) {
            dispatch( ParticipantAws.undo({id: currentData.id, faceId: currentData.last_face.id, hash: params.key}) )

            let lastFace = null
            let counter  = 0

            currentData.faces.all_faces = currentData.faces.all_faces.map(function(face){
                counter += 1

                if (currentData.last_face.id == face.id) {

                    currentData.participants.data = currentData.participants.data.map(function(participant) {
                        if (face.participant_id == participant.id ) {
                            // deduct from participant counter
                            if (participant.status == 'PRESENT') {
                                currentData.participants.active_with_no_face += 1
                            }

                            if (participant.status == 'INSERT') {
                                currentData.participants.insert_with_no_face += 1
                            }
                            
                            let _participant = participant
                                _participant.face = null

                            return _participant
                        }

                        return participant
                    })

                    currentData.faces.remaining += 1
                    let _face = face
                        _face.participant_id = null
                        _face.is_tagged = false
                        _face.is_archived = false

                    currentData.faces.active = face.id
                    
                    return _face
                }

                if (face.is_tagged || face.is_archived) {
                    lastFace = (lastFace && lastFace.updated_at > face.updated_at) ? lastFace : face
                }

                return face
            })

            if (counter == currentData.faces.all_faces.length) {
                currentData.last_face = lastFace
                callback(currentData)
            }
        } else {
            toastr.warning("All faces are available for tagging")
            callback(currentData)
        }
    },

    statusChange: (player, status, currentData, callback) => {
        let lastFace    = currentData.last_face

        if (status == 'PRESENT') {
            currentData.participants.active_with_no_face += 1
        } else {
            currentData.participants.active_with_no_face -= 1
        }

        let statusOrder = [1, 3, 5, 0]
        let players = _.sortBy(currentData.participants.data, function(o) { return statusOrder.indexOf(o.status_id); })
        currentData.participants.data = players.map(function(participant) {
            if (player.id == participant.id) {
                currentData.faces.all_faces = currentData.faces.all_faces.map(function(face){
                    if (face.participant_id == player.id) {
                        currentData.faces.remaining += 1
                        let newFace = face
                            newFace.participant_id = null
                            newFace.is_tagged = false
                            newFace.is_archived = false
                        
                        return newFace
                    }

                    return face
                })

                let _participant = participant
                    _participant.face = null
                    _participant.status = status

                return _participant
            }

            return participant
        })

        callback(currentData)
    }
}

export default TagUnTagReactionTransformer