import React, { Component } from 'react'
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Animated } from 'react-native'
import Swipeout             from 'rc-swipeout'
import Popup                from "reactjs-popup"
import { connect }          from 'react-redux'
import PropTypes            from 'prop-types'
import 'rc-swipeout/assets/index.css'
import SwipeOutList from '../../Components/Lists/SwipeOut'

import Participant from '../../Services/Handlers/Participant'
import ParticipantAws from '../../Services/Handlers/ParticipantAws'

class SwipeoutPlayerList extends Component {
	constructor(props) {
		super(props)

		this.state = {
			currentParticipant: null,
			modalVisible: false,
			players: null,
			filter: null,
			isFetching: null,
			currentBox: null,
			availableFace: null,
			officialTitles: null
		}

		this.setCurrentParticipant = this.setCurrentParticipant.bind(this)
		this.toggleEditModal = this.toggleEditModal.bind(this)
		this.markAbsent = this.markAbsent.bind(this)
		this.markArchived = this.markArchived.bind(this)
		this.handleClick = this.handleClick.bind(this)
	}

	componentWillMount() {
		this.setState({ players: this.props.players, filter: this.props.filter, isFetching: this.props.isFetching, currentBox: this.props.currentBox, availableFace: this.props.availableFace, officialTitles: this.props.officialTitles })
	}

	componentWillReceiveProps(props) {
		this.setState({ players: props.players, filter: props.filter, isFetching: props.isFetching, currentBox: props.currentBox, availableFace: props.availableFace, officialTitles: props.officialTitles })
	}

	setCurrentParticipant(participant) {
		this.setState({ currentParticipant: participant })
	}

	toggleEditModal() {
		this.props.editModal(this.state.currentParticipant)
	}

	markAbsent() {
		this.props.markAbsent(this.state.currentParticipant)
	}

	markArchived() {
		this.props.markArchived(this.state.currentParticipant)
	}

	handleClick(player) {
		this.props.tagParticipant(player)
	}

    render() {
    	const { currentParticipant, modalVisible, players, filter, isFetching, currentBox, availableFace, officialTitles } = this.state
    	const { settings } = this.props;

    	let _this = this
    	let titles = []
    	let btns   = [
			{
				text: 'Edit',
				onPress: () => this.toggleEditModal(),
				style: { backgroundColor: '#5DAE5D', color: 'white', width: '65px' }
			},
			{
				text: 'Remove',
				onPress:() => this.markArchived(),
				style: { backgroundColor: '#ff3737', color: 'white', width: '65px' }
			},
			{
				text: 'Absent',
				onPress:() => this.markAbsent(),
				style: { backgroundColor: '#ffc900', color: 'white', width: '65px' }
			}
		]

		if (isFetching) {
			return (<Text style={{ padding: '10px', color: 'white' }}> Loading Players... </Text>)
		}

		let tm_use_panel_numbers_setting = settings.data['TMUSEPANELNUMBERS'] || false;
		let is_tm_use_panel_numbers = tm_use_panel_numbers_setting ? parseInt(tm_use_panel_numbers_setting.value) : 0; // get in settings

    	let participants = players.map(function(player){
    		let playerName = (<span>{player.first_name.trim()} {player.last_name.trim()} <span className="badge bg-info">{is_tm_use_panel_numbers && player.indentifier ? player.indentifier : ''}</span></span>);
    		if (availableFace) {
	    		if ((player.status === 'PRESENT' || player.status === 'INSERT') && (!player.face || player.face.length == 0)) {
		    		if (filter && filter != '') {
		    			if (playerName.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
		    				return <SwipeOutList status={player.status} btns={btns} icon={" fa fa-exchange "} onOpen={() => _this.setCurrentParticipant(player)} onClose={() => console.log('close')} handleClick={() => _this.handleClick(player)} title={ playerName } _ref={`${player.id}`} />
		    			}
		    		} else {
		    			return <SwipeOutList status={player.status} btns={btns} icon={" fa fa-exchange "} onOpen={() => _this.setCurrentParticipant(player)} onClose={() => console.log('close')} handleClick={() => _this.handleClick(player)} title={ playerName } _ref={`${player.id}`} />
		    		}
	    		}
    		} else {
    			if (player.status === 'PRESENT' && (!player.face || player.face.length == 0)) {
		    		if (filter && filter != '') {
		    			if (playerName.toLowerCase().indexOf(filter.toLowerCase()) !== -1) {
		    				return <SwipeOutList status={player.status} btns={btns} icon={" fa fa-exchange "} onOpen={() => _this.setCurrentParticipant(player)} onClose={() => console.log('close')} handleClick={() => _this.handleClick(player)} title={ playerName } _ref={`${player.id}`} />
		    			}
		    		} else {
		    			return <SwipeOutList status={player.status} btns={btns} icon={" fa fa-exchange "} onOpen={() => _this.setCurrentParticipant(player)} onClose={() => console.log('close')} handleClick={() => _this.handleClick(player)} title={ playerName } _ref={`${player.id}`} />
		    		}
	    		}
    		}
    	})

    	return (
    		<View>
    			{ participants }
    		</View>
    	)
    }
}

SwipeoutPlayerList.propTypes = {
	dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	settings: state.Settings,
})

export default connect(mapStateToProps)(SwipeoutPlayerList)
