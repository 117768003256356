import React, { Component } from 'react'
import RenderIf             from 'render-if'
import { connect }          from 'react-redux'
import PropTypes            from 'prop-types'
import _                    from 'lodash'
import { View, Text, StyleSheet, ScrollView, Picker } from 'react-native'
import BoundingBox          from './Box'
import SwipeOutList         from '../../Components/Lists/SwipeOut'

const propTypes = {}
class PlayerList extends Component {
	constructor(props) {
		super(props)
		this.state = this.getInitialState()
	}
    getInitialState() {
        return {
            players: this.props.players
        }
    }
    componentWillMount() {
        this.setState({ players: this.props.players })
    }
    componentWillReceiveProps(props) {
        this.setState({ players: props.players })
    }
    componentCleanup() {
        this.setState({ ...this.getInitialStates() })
    }
    render() {
        const { players } = this.state
        const { settings } = this.props

        let _this   = this
        let content = ''

        let statusOrder = ['PRESENT', 'INSERT', 'ABSENT', 'REMOVED FROM TEAM LIST']
        let participants = _.sortBy(players, function(o) { return statusOrder.indexOf(o.status); })

        let tm_use_panel_numbers_setting = settings.data['TMUSEPANELNUMBERS'] || false;
        let is_tm_use_panel_numbers = tm_use_panel_numbers_setting ? parseInt(tm_use_panel_numbers_setting.value) : 0;

        return participants.map(player => {
            let btns    = [
                {
                    text: 'Edit',
                    onPress: () => _this.props.toggleEditModal(true),
                    style: { backgroundColor: '#5DAE5D', color: 'white', width: '65px', borderRadius: '100%', height: '65px', verticalAlign: 'middle', marginTop: '25px' }
                },
                {
                    text: 'Present',
                    onPress:() => _this.props.markPresent(player),
                    style: { backgroundColor: '#0098ff', color: 'white', width: '65px', borderRadius: '100%', height: '65px', verticalAlign: 'middle', marginTop: '25px' }
                },
                {
                    text: 'Absent',
                    onPress:() => _this.props.markAbsent(player),
                    style: { backgroundColor: '#ffc900', color: 'white', width: '65px', borderRadius: '100%', height: '65px', verticalAlign: 'middle', marginTop: '25px' }
                },
                {
                    text: 'Remove',
                    onPress:() => _this.props.markArchived(player),
                    style: { backgroundColor: '#ff3737', color: 'white', width: '65px', borderRadius: '100%', height: '65px', verticalAlign: 'middle', marginTop: '25px' }
                }
            ]

            const player_panel_number = is_tm_use_panel_numbers
                ? React.createElement('span', { className: 'badge' }, player.indentifier)
                : '';
            const br_elem = React.createElement('br');

            if (player.status == 'PRESENT') {
                btns = btns.filter( obj => obj.text !== 'Present' )
                if (player.face && player.face.all_faces && player.face.all_faces[0]) {
                    btns.push({
                        text: 'Undo',
                        onPress:() => _this.props.unTagPlayer(player.face.all_faces[0]),
                        style: { backgroundColor: 'orange', color: 'white', width: '65px', borderRadius: '100%', height: '65px', verticalAlign: 'middle', marginTop: '25px' }
                    })
                }

                content = React.createElement('div', { style: { height: '75px', display: 'table' } }, [
                    React.createElement(BoundingBox, {faces: ((player.face && player.face.all_faces) ? player.face.all_faces : null), isParticipantFace: true, optBox: ((player.face && player.face.all_faces) ? player.face.all_faces[0] : null)}, []),
                    React.createElement('div', {style: { marginLeft: '85px', display: 'table-cell', verticalAlign: 'middle', paddingLeft: '15px' }}, [
                        `${player.first_name} ${player.last_name} `,
                        player_panel_number,
                        br_elem,
                        React.createElement('small', {style: { fontWeight: '500', maxWidth: '55vw', color: 'gray'}}, player.status)
                    ])
                ])
            } else if (player.status == 'ABSENT') {
                btns = btns.filter( obj => obj.text !== 'Absent' )
                content = React.createElement('div', { style: { height: '75px', display: 'table' } }, [
                    React.createElement(BoundingBox, {faces: ((player.face && player.face.all_faces) ? player.face.all_faces : null), isParticipantFace: true}, []),
                    React.createElement('div', {style: { marginLeft: '85px', display: 'table-cell', verticalAlign: 'middle', paddingLeft: '15px', color: '#dede00' }}, [
                        `${player.first_name} ${player.last_name} `,
                        player_panel_number,
                        br_elem,
                        React.createElement('small', {style: { fontWeight: '500', maxWidth: '55vw', color: 'gray'}}, player.status)
                    ])
                ])
            } else if (player.status == 'REMOVED FROM TEAM LIST') {
                btns = btns.filter( obj => obj.text !== 'Remove' )
                content = React.createElement('div', { style: { height: '75px', display: 'table' } }, [
                    React.createElement(BoundingBox, {faces: ((player.face && player.face.all_faces) ? player.face.all_faces : null), isParticipantFace: true}, []),
                    React.createElement('div', {style: { marginLeft: '85px', display: 'table-cell', verticalAlign: 'middle', paddingLeft: '15px', color: 'red' }}, [
                        `${player.first_name} ${player.last_name} `,
                        player_panel_number,
                        br_elem,
                        React.createElement('small', {style: { fontWeight: '500', maxWidth: '55vw', color: 'gray'}}, player.status)
                    ])
                ])
            } else {
                content = React.createElement('div', { style: { height: '75px', display: 'table' } }, [
                    React.createElement(BoundingBox, {faces: ((player.face && player.face.all_faces) ? player.face.all_faces : null), isParticipantFace: true}, []),
                    React.createElement('div', {style: { marginLeft: '85px', display: 'table-cell', verticalAlign: 'middle', paddingLeft: '15px', color: 'red' }}, [
                        `${player.first_name} ${player.last_name} `,
                        player_panel_number,
                        br_elem,
                        React.createElement('small', {style: { fontWeight: '500', maxWidth: '55vw', color: 'gray'}}, player.status)
                    ])
                ])
            }

            return (
                <View>
                    <SwipeOutList
                        btns={btns}
                        icon={" fa fa-exchange "}
                        onOpen={() => _this.props.setCurrentParticipant(player)}
                        onClose={() => console.log('close')}
                        handleClick={() => _this.props.handleClick(player)}
                        title={content}
                        _ref={`${player.id}`}
                        customStyle={{ fontWeight: '500', marginLeft: '80px', marginTop: '-55px', height: '100%' }}
                        swipeTriggerStyle={{ padding: '35px', float: 'right', width: '10vw' }} />
                </View>
            )
        })
    }
}

const styles = StyleSheet.create({
	app: {
		display: 'block',
		flex: 1
	},
	appHeader: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: 'white'
    },
	appBodyContainer: {
        margin: '10px',
        height: '100%'
    },
	appBodyHeader: {
		flex: 1,
		width: '95vw',
		height: '100px',
		display: 'inline-block'
	},
	appBodyContent: {
		// backgroundColor: 'white',
		display: 'inline-block',
		width: '95vw',
		flex: 1
	},
    appBody: {
        flex: 1,
        height: 'inherit'
    },
})

PlayerList.propTypes = {
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    settings: state.Settings,
})

export default connect(mapStateToProps)(PlayerList)