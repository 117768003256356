import API from '../Models/API'
import ParticipantAwsModel from '../Models/ParticipantAws'

const ParticipantAws = {
	assign: (requestBody, callback) => {
		let endpoint = ParticipantAwsModel.assign(requestBody.id, requestBody.faceId)
		return API.post(endpoint, {}, callback)
	},

	unAssign: (requestBody, callback) => {
		let endpoint = ParticipantAwsModel.unAssign(requestBody.id)
		return API.post(endpoint, {}, callback)
	},

	undo: (participantGroupId, faceId, callback) => {
		let endpoint = ParticipantAwsModel.undo(participantGroupId)
		return API.post(endpoint, {id: faceId}, callback)
	}
}

export default ParticipantAws