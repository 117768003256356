const Setting = {
    fields: () => ({
        id: 0,
        name: null,
        key: null,
        value: null,
        label: null,
        linking_type_id: null,
        type: null,
        sub_type: null,
    }),

    getByKey: (key, linking_type_id = false) => `settings/key/${key}?linking_type_id=${linking_type_id || ''}`,
};

export default Setting;