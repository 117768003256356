import React, { Component } from 'react'
import { browserHistory }   from 'react-router'
import RenderIf             from 'render-if'
import { connect }          from 'react-redux'
import PropTypes            from 'prop-types'
import { View, Text, StyleSheet, TextInput } from 'react-native'

import PrimaryText         from '../../Components/Texts/Primary'
import SecondaryText       from '../../Components/Texts/Secondary'
import HeaderWidget        from '../../Components/Widgets/Header'
import LargeButton         from '../../Components/Buttons/Large'
import ParticipantGroupAws from '../../Services/Handlers/ParticipantGroupAws'

const propTypes = {}
class TeamFeedbackScene extends Component {
	constructor(props) {
		super(props)
		this.state = {
			emotion: ''
		}
		this.navigateToPreviewsScene = this.navigateToPreviewsScene.bind(this)
		this.selectEmotion = this.selectEmotion.bind(this)
		this.submitFeedback = this.submitFeedback.bind(this)
		this.navigateToThankYouScene = this.navigateToThankYouScene.bind(this)
	}

	navigateToPreviewsScene() {
        const { key } = this.props.params
        browserHistory.push(`/${key}/signoff`)
    }

    navigateToThankYouScene() {
        const { key } = this.props.params
        browserHistory.push(`/${key}/thank-you`)
    }

    selectEmotion(ref) {
    	if (this.refs) {
    		for (var _ref in this.refs) {
    			let fontSize = '10pt'
    			let fontWeight = 'normal'
    			if (_ref == ref) {
    				fontSize = '11pt'
    				fontWeight = 'bold'
    				this.setState({emotion: ref})
    			}
    			this.refs[_ref].style.fontSize = fontSize
    			this.refs[_ref].style.fontWeight = fontWeight
		    }

    	}
    }

    submitFeedback() {
    	const { dispatch, location } = this.props
    	if (this.refs['feedback-comments']) {
    		dispatch(ParticipantGroupAws.sendFeedback({
    			id: (location.state && location.state.participant_group_id) ? location.state.participant_group_id : 0,
    			feedback: this.state.emotion, 
    			feedback_details: this.refs['feedback-comments'].value
    		}, this.navigateToThankYouScene))
    	}
    }

    render() {
    	let _this = this
    	let emotions = [
    		{
    			first: true,
    			title: 'Awesome',
    			color: '#709260',
    			ref: 'Awesome',
    			url: "https://www.sportsinfocus.com.au/assets/modules/feedback/emoticon/awesome.png"
    		},
    		{
    			title: 'Average',
    			color: '#bb9b3d',
    			ref: 'Average',
    			url: "https://www.sportsinfocus.com.au/assets/modules/feedback/emoticon/average.png"
    		},
    		{
    			title: 'Terrible',
    			color: '#ab706c',
    			ref: 'Terrible',
    			url: "https://www.sportsinfocus.com.au/assets/modules/feedback/emoticon/terrible.png"
    		}
    	]
 
    	return (
    		<View style={{ textAlign: 'center', background: 'linear-gradient(0deg,#00486b 0,#00529e)', minHeight: '100vh' }}>
    			<View style={styles.app}>
    				<View style={styles.appHeader}>
                        <HeaderWidget leftAction={this.navigateToPreviewsScene} leftTitle="Back" leftIcon="fa fa-angle-left fa-2x" rightAction={() => {}} title="Feedback" />
                    </View>
                    <View style={styles.appBody}>
	    				<View style={styles.appBodyContainer}>
	    					<div>
		    					<View style={styles.appBodyHeader}>
		    						<PrimaryText text="Thanks For Your Time!" />
	                                <SecondaryText text="We'd love to get your feedback and thoughts on this process. How's your experience?" />
		    					</View>

		    					<View style={styles.appBodyContent1}>
		    						{
		    							emotions.map(function(emo){
		    								if (emo.first) {
			    								return (
			    									<div ref={emo.ref} style={{ float: 'left', width: '25vw', marginLeft: '7vw', color: emo.color }} onClick={() => _this.selectEmotion(emo.ref)}>
							    						<img src={emo.url} style={{ height: '50px' }} /> <br /> {emo.title}
							    					</div>
			    								)
		    								}

		    								return (
		    									<div ref={emo.ref} style={{ float: 'left', width: '25vw', color: emo.color }} onClick={() => _this.selectEmotion(emo.ref)}>
						    						<img src={emo.url} style={{ height: '50px' }} /> <br /> {emo.title}
						    					</div>
		    								)
		    							})
		    						}
		    					</View>
		    					<View style={styles.appBodyContent2}>
		    						<textarea ref="feedback-comments" style={{height: '40vh', width: '100%', padding: '15px'}} placeholder=" How could we improve? " />
		    					</View>
		    				</div>
	    					<div>
		    					<View style={{ flex: 1, width: '95vw', marginTop: '5px' }}> 
		    						<LargeButton action={() => this.submitFeedback()} title="SUBMIT FEEDBACK" />
		    					</View>
		    				</div>
	    				</View>
                    </View>
    			</View>
    		</View>
    	)
    }
}

const styles = StyleSheet.create({
	app: {
		display: 'block',
		flex: 1
	},
	appHeader: {
        flex: 1, 
        justifyContent: 'center',
        backgroundColor: 'white'
    },
	appBody: {
		flex: 1
	},
	appBodyContainer: { 
        margin: '10px'
    },
	appBodyHeader: { 
		flex: 1,
		width: '95vw',
		height: '100px',
		display: 'inline-block'
	},
	appBodyContent1: { 
		backgroundColor: 'white',
		display: 'inline-block',
		width: '95vw',
		padding: '15px',
		paddingBottom: '25px',
		flex: 1
	},
	appBodyContent2: { 
		backgroundColor: 'white',
		display: 'inline-block',
		marginTop: '10px',
		height: '40vh',
		width: '95vw',
		flex: 1
	}
})

TeamFeedbackScene.propTypes = {
  dispatch: PropTypes.func.isRequired
}

export default connect()(TeamFeedbackScene)