import React, { Component } from 'react'
import { View, Text, StyleSheet, TouchableOpacity, ScrollView } from 'react-native'

class DrawerWidget extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		let menuList = [
			{
				title: 'Menu 1',
				icon: 'fa fa-eye',
				action: () => {}
			}, {
				title: 'Menu 2',
				icon: 'fa fa-eye',
				action: () => {}
			}, {
				title: 'Menu 3',
				icon: 'fa fa-eye',
				action: () => {}
			}, {
				title: 'Menu 4',
				icon: 'fa fa-eye',
				action: () => {}
			}, {
				title: 'Menu 5',
				icon: 'fa fa-eye',
				action: () => {}
			}, {
				title: 'Menu 6',
				icon: 'fa fa-eye',
				action: () => {}
			}
		]

		let menu = menuList.map(function(m){
			return (
				<TouchableOpacity style={{ padding: '20px', textAlign: 'left' }} onClick={() => m.action()}>
	                <Text> {m.title} <i className={m.icon} style={{float: 'right'}}></i></Text>
	            </TouchableOpacity>
			)
		})

	    return (
		    <View style={styles.sideMenuContainer}>
		        <ScrollView>
		            <div style={{ padding: '20px', textAlign: 'left', width: '100%', borderBottom: '1px solid gray' }}>
		                <img src="https://www.sportsinfocus.com.au/assets/new-homepage/img/resources/logo.png" style={{width: 'inherit'}}/>
		            </div>
		            { menu }
		        </ScrollView>
		    </View>
	    )
	}
}

const styles = StyleSheet.create({
    sideMenuContainer: {
        flex:1,
        backgroundColor: '#1e252b',
        color: 'white',
        maxWidth: '300px'
    }
})

export default DrawerWidget;