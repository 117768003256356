/* *
 * This concept was taken from:
 * https://thinkster.io/tutorials/react-redux-ajax-middleware
 *
 * Codebase on:
 * https://github.com/gothinkster/react-redux-realworld-example-app
 * */

import superagentPromise from 'superagent-promise'
import _superagent      from 'superagent'
import { toastr }       from 'react-redux-toastr'
import { API_BASE_URL } from '../../.env'

const superagent = superagentPromise(_superagent, global.Promise)

const errHandler = function(err) {
    console.log(err)
}

const API = {

	post: (endpoint, requestBody, callback) =>
        superagent.post(`${API_BASE_URL}${endpoint}`, requestBody)
            .then(res => {
                // console.log("API RESULT", res)
                if (res && res.body && res.body.data && res.body.data.ERROR) {
                    toastr.error(res.body.data.ERROR, 'Please call customer service to know more about this error.')
                } else {
                    return callback(res.body)
                }
            })
            .catch(errHandler),

    get: (endpoint, callback) => 
        superagent.get(`${API_BASE_URL}${endpoint}`)
            .then(res => {
                // console.log("API RESULT", res)
                if (res && res.body && res.body.data && res.body.data.ERROR) {
                    toastr.error(res.body.data.ERROR, 'Please call customer service to know more about this error.')
                } else {
                    return callback(res.body)
                }
            })
            .catch(errHandler),

    put: (endpoint, requestBody, callback) =>
        superagent.put(`${API_BASE_URL}${endpoint}`)
            .send(requestBody)
            .then(res => {
                // console.log("API RESULT", res)
                if (res && res.body && res.body.data && res.body.data.ERROR) {
                    toastr.error(res.body.data.ERROR, 'Please call customer service to know more about this error.')
                } else {
                    return callback(res.body)
                }
            })
            .catch(errHandler),

    directPost: (endpoint, requestBody, callback) =>
        superagent.post(endpoint, requestBody)
            .then(res => {
                // console.log("API RESULT", res)
                if (res && res.body && res.body.data && res.body.data.ERROR) {
                    toastr.error(res.body.data.ERROR, 'Please call customer service to know more about this error.')
                } else {
                    return callback(res.body)
                }
            })
            .catch(errHandler),
} 

export default API