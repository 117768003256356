//eslint-disable-next-line
// This component needs refactor
import React, { Component } from 'react'
import { connect }          from 'react-redux'
import PropTypes            from 'prop-types'
import { toastr }           from 'react-redux-toastr'
import ReactTooltip         from 'react-tooltip'
import RenderIf             from 'render-if'
import { View, Text, StyleSheet, TouchableOpacity, ScrollView } from 'react-native'

const propTypes = {}

class PlayerBoundingBox extends Component {
	constructor(props) {
		super(props)
        this.state = {
            active: this.props.optBox
        }
        this.designMainBox = this.designMainBox.bind(this)
		this.designBox = this.designBox.bind(this)
		this.designZoomedImage = this.designZoomedImage.bind(this)
        this.designMainPreview = this.designMainPreview.bind(this)
		this.designParticipantFace = this.designParticipantFace.bind(this)
        this.setActiveFace = this.setActiveFace.bind(this)
        this.excludeIncludeFace = this.excludeIncludeFace.bind(this)
	}

    componentWillReceiveProps(props) { this.setState({ active: props.optBox }) }
    componentDidUpdate() { ReactTooltip.rebuild() }
	designZoomedImage(face) {
        const { file } = face
        const { zoomed } = this.props

        let screenWidth = window.innerWidth - (window.innerWidth * 0.1)
        let previewWidth = 250
        let widthDiff = previewWidth / file.file_width 
        let previewHeight  = file.file_height * widthDiff

        let left = face.aws_bb_left * previewWidth
        let top = face.aws_bb_top * previewHeight
        let width = ((face.aws_bb_width * previewWidth))
        let height = ((face.aws_bb_height * previewHeight))

        let style = {
            background: `url(${(face) ? face.url : ""}) ${ -left + ((previewWidth/100)  ) }px ${-top}px / 250px`,
            zoom: (zoomed) ? zoomed : (5 * (screenWidth / previewWidth )),
            width: (this.props.width) ? this.props.width : width + (10 * (screenWidth / previewWidth )),
            height: (this.props.height) ? this.props.height : height + (10 * (screenWidth / previewWidth )),
            marginLeft: 'auto',
            marginRight: 'auto',
        }

        return <div style = {style}>&nbsp;</div>
	}

	designParticipantFace(faces) {

        let style = {
            float: 'left',
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            backgroundColor: '#dcd8d8'
        }

        if (faces && faces[0]) {
            let face = faces[0]
            const { file, face_url, use_sprite } = face

            let url = (face) ? face.url : ""
                url = (!use_sprite) ? face_url : url

            let previewWidth = 250
            let widthDiff = previewWidth / file.file_width 
            let previewHeight  = file.file_height * widthDiff

            let left = face.aws_bb_left * previewWidth
            let top = face.aws_bb_top * previewHeight
            let width = ((face.aws_bb_width * previewWidth))
            let height = ((face.aws_bb_height * previewHeight))

            let leftAndTop = (!use_sprite) ? 'no-repeat center / 100%' : `${ -left + ((previewWidth/100)  ) }px ${-top}px / 250px`

            style = {
                background: `url("${ url }") ${ leftAndTop }`,
                position: 'inherit',
                color: 'white',
                height: '100px',
                width: '100px',
                borderRadius: '50%'
            }

            if (use_sprite) {
                style['background'] = `url(${(face) ? face.url : ""}) ${ -left + ((previewWidth/100)  ) }px ${-top}px / 250px`
                style['zoom']   = ((50 / width) > 2) ? 50 / width : 1
                style['width']  = '100px'
                style['height'] = '100px'
            }
        }

        return <div style = {style}>&nbsp;</div>
	}

	designBox(face, previewWidth, previewHeight, isWholePhoto = false, currentBox = 0) {
    	let left = face.aws_bb_left * previewWidth
        let top = face.aws_bb_top * previewHeight
        let width = ((face.aws_bb_width * previewWidth))
        let height = ((face.aws_bb_height * previewHeight))

        // styles
        let style = {
            background: `url(${(face) ? face.url : ""}) ${ -left + ((previewWidth/100) * 7 ) }px ${-top}px / 88vw`,
            height: '75px',
            float: 'right',
            position: 'absolute',
            width: width,
            color: 'white'
        }

        if (isWholePhoto) {
        	style = {
                border: (currentBox == face.id) ? '3px solid red' : '3px solid yellow', 
                position: 'absolute', 
                left: left,
                top: top,
                width: width,
                height: height,
                color: 'white'
            }
        }

        this.props.setCurrentBox(face.id, face.file_id, face.url)
        
        if (this.props.squareFace) {
            return this.designMainBox(face)
        }

        if (this.props.isZoomedImage) {
        	return this.designZoomedImage(face)
        }

        return (<div style={style}>&nbsp;</div> )
	}

    designMainBox(face) {
        const { file, face_url, use_sprite } = face

        let previewWidth = 250
        let widthDiff = previewWidth / file.file_width 
        let previewHeight  = file.file_height * widthDiff

        let left = face.aws_bb_left * previewWidth
        let top = face.aws_bb_top * previewHeight
        let width = ((face.aws_bb_width * previewWidth))
        let height = ((face.aws_bb_height * previewHeight))

        let url = (face) ? face.url : ""
            url = (!use_sprite) ? face_url : url

        let leftAndTop = (!use_sprite) ? 'no-repeat center / 100%' : `${ -left + ((previewWidth/100)  ) }px ${-top}px / 250px`

        let style = {
            background: `url("${ url }") ${ leftAndTop }`,
            position: 'absolute',
            minWidth: '10px',
            color: 'white',
            zoom: (!use_sprite) ? null : (((50 / width) > 2) ? 50 / width : 1),
            maxHeight: (!use_sprite) ? null : '15px',
            height: 'inherit',
            width: 'inherit'
        }

        if ((50 / width) <= 2) {
            style['width'] = width
            style['height'] = height
            style['maxHeight'] = '75px'
            style['minWidth'] = '75px'
        } 

        return <div style = {style}>&nbsp;</div>
    }

    // set active face 
    setActiveFace(face) {
        const { currentFile } = this.props
        const { active } = this.state
        if (active != face.id) {
            if (!face.is_tagged) {
                this.props.setCurrentBox(face.id, currentFile)
            }

            this.setState({ active: face.id })
        }
    }

    excludeIncludeFace(face, actions) {
        switch(actions) {
            case 'UNTAG':
            case 'INCLUDE':
                return this.props.untagParticipant(face.id)

            case 'EXCLUDE':
                return this.props.tagParticipant({id: 0}, face.id)
        }

        this.setState({ active: 0 })
    }

    designMainPreview(face, previewWidth, previewHeight, currentBox = 0, signoff=false) {
        let that = this
        let left = face.aws_bb_left * previewWidth + ((previewWidth == 800 || previewWidth == 500) ? 55 : 0)
        let top = face.aws_bb_top * previewHeight + ((previewWidth == 800 || previewWidth == 500) ? 50 : 0)
        let width = ((face.aws_bb_width * previewWidth)) + ((previewWidth == 800 || previewWidth == 500) ? 15 : 0)
        let height = ((face.aws_bb_height * previewHeight)) + ((previewWidth == 800 || previewWidth == 500) ? 15 : 0)

        let display = (this.state.active == face.id) ? 'block' : 'none'

        // let boxActions = (face.is_tagged) ? 'UNTAG' : ((face.is_archived) ? 'INCLUDE' : 'EXCLUDE')
        let boxActions = (face.is_tagged) ? 'UNTAG' : null
        let boxActionColor = (face.is_tagged) ? 'orange' : (face.is_archived) ? 'green' : 'red'

        let backgroundColor = (face.is_archived) ? '#8080807d' : 'transparent'
        let border = (face.is_archived) ? '1px solid red' : '1px solid white'

        if (currentBox == face.id) {
            border = '3px solid #0000ff'
        }

        if (face.is_tagged) {
            border = '1px solid #0ccc0c'
            backgroundColor = '#0ccc0c38'
        }

        let style = {
            border: border, 
            position: 'absolute', 
            left: left + ((previewWidth == 800 || previewWidth == 500) ? (previewWidth/100) : 0),
            top: top ,
            width: width,
            height: height,
            color: 'white',
            cursor: 'pointer',
            backgroundColor: backgroundColor
        }

        let prevStyle = {
            backgroundColor: '#222',
            position: 'absolute', 
            padding: '0 5px',
            color: 'white',
            'margin-top': height,
            'margin-left': -(width/2),
            'border-radius': '2px'
        }

        if (signoff) {
            return (<div id={face.id} style={style}>
                <div style={prevStyle}>{(face.is_tagged) ? face.participant_full_name : null}</div>
            </div>)
        } else {
            return (<div id={face.id} style={style} onClick={(e) => that.setActiveFace(face)} data-tip={(face.is_tagged) ? face.participant_full_name : null}>
                <ReactTooltip />
                <div style={{backgroundColor: boxActionColor, width: width + 15, marginTop: '50px', display: display}} onClick={() => that.excludeIncludeFace(face, boxActions)}>{ boxActions }</div>
            </div>)
        }
    }

    render() {
        // caching 
    	let that = this
    	const { singlePhoto, isZoomedImage, faces, isParticipantFace, currentFile, optBox, zoomed, squareFace, bigImagePreview } = this.props

    	let _currentBox = 0

        if (isParticipantFace) {
            return that.designParticipantFace(faces)
        } else if (faces) {
	        return faces.map(function(face){
                const { file } = face

                if (file) {
                    let previewWidth = window.innerWidth - (window.innerWidth * 0.023)
                    
                    if (bigImagePreview) {
                        previewWidth = that.props.previewWidth
                        let widthDiff = previewWidth / file.file_width
                        let previewHeight  = file.file_height * widthDiff

                        if (face.file_id == currentFile) {
                            return that.designMainPreview(face, previewWidth, previewHeight, optBox)
                        }

                        if (currentFile.id) {
                            if (face.file_id == currentFile.id) {
                                return that.designMainPreview(face, previewWidth, previewHeight, optBox, true)
                            }  
                        }
                    }

                    let widthDiff = previewWidth / file.file_width
                    let previewHeight  = file.file_height * widthDiff

                    if (squareFace) {
                        if (optBox == face.id) {
                            that.props.setCurrentBox(face.id, face.file_id, face.url)
                            return that.designMainBox(face)
                        }
                    } else if ( singlePhoto || isZoomedImage || isParticipantFace ) {
                        if (optBox == face.id) {
                            console.log('faceId', face.id)
                            return that.designBox(face, previewWidth, previewHeight, false, optBox)
                        }

                        if (!face.is_tagged && !face.is_archived && _currentBox == 0) {
    		        		_currentBox = face.id
    		        		return that.designBox(face, previewWidth, previewHeight, false, optBox)
    		        	}
    	        	} else if (face.file_id == currentFile) {
                        if (!face.is_tagged && !face.is_archived && _currentBox == 0) {
                            _currentBox = face.id
                        }
    		        	return that.designBox(face, previewWidth, previewHeight, true, optBox)
    	        	}
                }
	        })
    	}

    	return <div></div>
    }
}

// styles
const styles = StyleSheet.create({
	app: {
		display: 'block',
		flex: 1
	},
	appHeader: {
		flex: 0.01,
		justifyContent: 'center',
		backgroundColor: 'white'
	},
	appBody: {
		flex: 1,
		background: 'linear-gradient(0deg,#00486b 0,#00529e)'
	},
	appBodyContainer: { 
		flex: 1,
		marginTop: '5px',
		height: '80vh'
	},
	appBodyContent: { 
		backgroundColor: 'white',
		width: '95vw',
		display: 'inline-block',
		flex: 1
	}
})

PlayerBoundingBox.propTypes = {
  dispatch: PropTypes.func.isRequired
}

export default connect()(PlayerBoundingBox)