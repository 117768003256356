import { SETTING_REQUEST, SETTING_SUCCESS } from '../Handlers/Setting';

const Settings = (
    state = {
        isFetching: false,
        data: [],
    }, action) => {
    switch (action.type) {
        case SETTING_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case SETTING_SUCCESS:
            let state_data = state.data;
            const { key, data } = action;

            state_data[key] = data;

            return {
                ...state,
                isFetching: false,
                data: state_data,
            };
        default:
            return state;
    }
}

export default Settings;