import moment from 'moment-timezone'

const Default = {
    format:   _ => 'YYYY-MM-DD HH:MM:SS',
    timezone: _ => 'Australia/Brisbane'
}

const Calendar = {
    now: _ => moment().tz(Default.timezone()).format(Default.format())
}

export default Calendar