import PGARepository from '../Repositories/ParticipantGroupAws'
import PGATransformer from '../Transformers/ParticipantGroupAws'

export const PARTICIPANT_GROUP_AWS_REQUEST = 'PARTICIPANT_GROUP_AWS_REQUEST'
export const PARTICIPANT_GROUP_AWS_SUCCESS = 'PARTICIPANT_GROUP_AWS_SUCCESS'
export const PARTICIPANT_GROUP_AWS_FAILED = 'PARTICIPANT_GROUP_AWS_FAILED'

const ParticipantGroupAws = {
    getAll: (hash, noRefresh = false, callback = false) => (dispatch, getState) => {
        if (!noRefresh) {
    	   dispatch(ParticipantGroupAws.request())
        }

    	PGARepository.getAll(hash, (data) => {
    		if (data.error)
    			dispatch(ParticipantGroupAws.failed(data))

    		dispatch(ParticipantGroupAws.success(data))

            if (callback) callback(data);
    	} )
    },
    sendFeedback: (params, callback) => (dispatch, getState) => {
        PGARepository.sendFeedback(params, (data) => {
            if (data.error)
                return dispatch(ParticipantGroupAws.failed(data))

            return callback()
        })
    },
    signOff: (id, callback) => (dispatch, getState) => {
        PGARepository.signOff(id, (data) => {
            if (data.error)
                return dispatch(ParticipantGroupAws.failed(data))

            return callback()
        })
    },
    request: () => ({ type: PARTICIPANT_GROUP_AWS_REQUEST }),
    success: (data) => ({ type: PARTICIPANT_GROUP_AWS_SUCCESS, data: data }),
    failed: (data) => ({ type: PARTICIPANT_GROUP_AWS_FAILED }),
}

export default ParticipantGroupAws