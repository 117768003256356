import ParticipantRepository from '../Repositories/Participant'
import ParticipantModel from '../Models/Participant'
import ParticipantGroupAws from './ParticipantGroupAws'
import ParticipantAws from './ParticipantAws'

export const PARTICIPANT_REQUEST = 'PARTICIPANT_REQUEST'
export const PARTICIPANT_SUCCESS = 'PARTICIPANT_SUCCESS'
export const PARTICIPANT_FAILED  = 'PARTICIPANT_FAILED'

const Participant = {
    create: (params, callback) => (dispatch, getState) => {
    	dispatch(Participant.request())

    	ParticipantRepository.create( params,  (res) => {
            if (res.error)
                return dispatch(Participant.failed(res))

            if (params.currentFace) {
                return dispatch( ParticipantAws.assign({ id: res.data.id, faceId: params.currentFace, hash: params.hash }, callback) )
            }
            
            return callback()
        })
    },
    
    update: (params, callback) => (dispatch, getState) => {
        dispatch(Participant.request())

        ParticipantRepository.update( params, (res) => {
            if (res.error)
                return dispatch(Participant.failed(res))

            callback()
            // return dispatch(ParticipantGroupAws.getAll(params.hash, true))
        })
    },

    markAbsent: ( params ) => (dispatch, getState) => {
        dispatch(Participant.request())

        ParticipantRepository.markAbsent(params.id, (res) => {
            if (res.error)
                return dispatch(Participant.failed(res))

            if (params.face && params.face.id) {
                return dispatch(ParticipantAws.unAssign({ id: params.face.id, hash: params.hash }))
            }

            // return dispatch(ParticipantGroupAws.getAll(params.hash, true))
        })
    },

    markArchived: (params) => (dispatch, getState) => {
        dispatch(Participant.request())

        ParticipantRepository.markArchived(params.id, (res) => {
            if (res.error)
                return dispatch(Participant.failed(res))

            if (params.face && params.face.id) {
                return dispatch(ParticipantAws.unAssign({ id: params.face.id, hash: params.hash }))
            }

            // return dispatch(ParticipantGroupAws.getAll(params.hash, true))
        })
    },

    markPresent: (params) => (dispatch, getState) => {
        dispatch(Participant.request())

        ParticipantRepository.markPresent(params.id, (res) => {
            if (res.error)
                return dispatch(Participant.failed(res))

            // return dispatch(ParticipantGroupAws.getAll(params.hash, true))
        })
    },

    request: ()     => ({ type: PARTICIPANT_REQUEST }),
    success: (data) => ({ type: PARTICIPANT_SUCCESS }),
    failed:  (data) => ({ type: PARTICIPANT_FAILED  }),
}

export default Participant