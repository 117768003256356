import API from "../Models/API";
import SettingModel from "../Models/Setting";

const Setting = {
    getByKey: (key, linking_type_id = false, callback = false) => {
        API.get(SettingModel.getByKey(key, linking_type_id), (res) => {
            if (callback) return callback(res);
            return res;
        });
    },
};

export default Setting;