import React, { Component } from 'react'
import { browserHistory }   from 'react-router'
import RenderIf             from 'render-if'
import { connect }          from 'react-redux'
import PropTypes            from 'prop-types'
import { View, Text, StyleSheet, TextInput } from 'react-native'
const propTypes = {}
class ThankYouScene extends Component {
	constructor(props) {
		super(props)
	}

    render() {
 
    	return (
			<View style={styles.app}>
				<View style={styles.appBody}>
					<View>
						<Text style={styles.appIcon}>
							<i className="fa fa-check-square-o fa-4x" />
						</Text>
						<Text style={styles.appHeader}>THANK YOU</Text>
						<Text style={styles.appContent}>
							You have successfully finished tagging all the players of this team. 
							If you have more questions, please contact us at <a href="mailto:customerservice@sportsinfocus.com.au" style={{color: '#a7c1d8'}}>customerservice@sportsinfocus.com.au</a>
						</Text>
					</View>
				</View>
			</View>
    	)
    }
}

const styles = StyleSheet.create({
	app: {
		display: 'block',
		background: 'linear-gradient(0deg,#00486b 0,#00529e)',
		flex: 1,
		height: '100vh'
	},
	appBody: {
		flex: 1,
		color: 'white'
	},
	appIcon: {
		textAlign: 'center',
		marginTop: '15vh',
		marginBottom: '30px'
	},
	appHeader: {
		textAlign: 'center',
		fontSize: 'xx-large',
    	fontFamily: 'sans-serif'
	},
	appContent: {
		textAlign: 'center',
		fontSize: '9pt',
	    margin: '30px',
	    color: '#a7c1d8',
    	fontFamily: 'sans-serif'
	},
})

ThankYouScene.propTypes = {
  dispatch: PropTypes.func.isRequired
}

export default connect()(ThankYouScene)