import SettingRepository from '../Repositories/Setting';

export const SETTING_REQUEST = 'SETTING_REQUEST';
export const SETTING_SUCCESS = 'SETTING_SUCCESS';

const Setting = {
    getByKey: (key, linking_type_id = false, callback = false) => (dispatch) => {
        dispatch(Setting.request());
        SettingRepository.getByKey(key, linking_type_id, (res) => {
            dispatch(Setting.success(key, res.data));
        });
    },


    request: () => ({ type: SETTING_REQUEST }),
    success: (key, data) => ({ type: SETTING_SUCCESS, key, data}),
}

export default Setting;