import React, { Component } from 'react'

class LargeButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: true
        }
        this.handleClick = this.handleClick.bind(this)
    }
    handleClick() {
        if (this.state.active) {
            this.props.action()
            this.setState({ active: false })
        }
    }
    render() {
        return ( <button style={{
                width: '100%',
                backgroundColor: (this.state.active) ? '#F2D608' : '#f7f776',
                color: '#00529d',
                border: '#dbc20e',
                fontWeight: 500,
                display: 'inline-block',
                right: 0,
                fontSize: '14pt',
                borderRadius: '5px',
                padding: '15px'
            }} onClick={this.handleClick}> {(this.state.active) ? this.props.title : '...'} </button> )
    }
}

export default LargeButton;