import ParticipantAwsRepo  from '../Repositories/ParticipantAws'
import ParticipantGroupAws from '../Handlers/ParticipantGroupAws'

export const PARTICIPANT_AWS_REQUEST = 'PARTICIPANT_AWS_REQUEST'
export const PARTICIPANT_AWS_SUCCESS = 'PARTICIPANT_AWS_SUCCESS'
export const PARTICIPANT_AWS_FAILED  = 'PARTICIPANT_AWS_FAILED'

const ParticipantAws = {
    assign: (params, callback = null) => (dispatch, getState) => {
    	dispatch(ParticipantAws.request())

        ParticipantAwsRepo.assign(params, (data) => {
            if (data.error)
                return dispatch(ParticipantAws.failed(data))

            if (callback) { callback() }
            return dispatch(ParticipantAws.success(data))
        })
    },

    unAssign: (params) => (dispatch, getState) => {
        dispatch(ParticipantAws.request())

        ParticipantAwsRepo.unAssign(params, (data) => {
            if (data.error)
                return dispatch(ParticipantAws.failed(data))

            return dispatch(ParticipantAws.success(data))
        })
    },

    undo: (params) => (dispatch, getState) => {
        dispatch(ParticipantAws.request())

        ParticipantAwsRepo.undo(params.id, params.faceId, (data) => {
            if (data.error)
                return dispatch(ParticipantAws.failed(data))

            return dispatch(ParticipantAws.success(data))
        })
    }, 
    
    request: () => ({ type: PARTICIPANT_AWS_REQUEST }),
    success: (data) => ({ type: PARTICIPANT_AWS_SUCCESS, data: data }),
    failed: (data) => ({ type: PARTICIPANT_AWS_FAILED }),
}

export default ParticipantAws