import React, { Component } from 'react'
import { Text, View } from 'react-native'
import RenderIf from 'render-if'

class HeaderWidget extends Component {
    render() {
        const { leftHasBorders } = this.props
        return (
            <View> 
                <div style={{ padding: '5px' }}>

                    <div style={{ padding: '5px 10px', float: 'left', width: '30%' }} onClick={this.props.leftAction}>
                        {RenderIf(leftHasBorders)(
                            <i className={this.props.leftIcon} style={{ 
                                float: 'left',
                                fontSize: '12pt', 
                                border: '1px solid #d2c9c9', 
                                padding: '5px 10px' }} />)}

                        {RenderIf(!leftHasBorders)(<i className={this.props.leftIcon} style={{ fontSize: '12pt', padding: '5px 10px', float: 'left' }} />)}
                        
                        {RenderIf(this.props.leftTitle)(<span style={{ float: 'left', marginTop: '4px' }}> {this.props.leftTitle} </span>)}
                    </div>

                    <div className="col-md-8 main-title" style={{ padding: '5px 10px', textAlign: 'center',  fontSize: '14pt', float: 'left', width: '40%' }}>
                    	{ this.props.title }
                    </div>

                    <div style={{ float: 'right', marginRight: '3%', color: 'darkslategray' }} >
                        {RenderIf(this.props.rightIcon)(<div style={{ float: 'right' }}>
                            <button style={{ 
                                    fontSize: '12pt', border: 'none', 
                                    padding: '3px 10px', 
                                    marginRight: '5px',
                                    marginTop: '5px',
                                    backgroundColor: 'white',
                                    border: '1px solid #c1bfbf'
                                }}
                                onClick={this.props.rightAction}
                                title={this.props.rightTitle}
                                >
                                <i className={this.props.rightIcon} style={{ marginRight: '5px' }} />
                                {(window.innerWidth > 800) ? this.props.rightTitle : ''}
                            </button>
                        </div>)}

                        {RenderIf(this.props.rightIcon2)(<div style={{ float: 'right' }}>
                            <button style={{ 
                                    fontSize: '12pt', border: 'none', 
                                    padding: '3px 10px', 
                                    marginRight: '5px',
                                    marginTop: '5px',
                                    backgroundColor: 'white',
                                    border: '1px solid #c1bfbf'
                                }}
                                onClick={this.props.rightAction2}
                                title={this.props.rightTitle2}
                                >
                                <i className={this.props.rightIcon2} style={{ marginRight: '5px' }} />
                                {(window.innerWidth > 800) ? this.props.rightTitle2 : ''}
                            </button>
                            
                        </div>)}
                    </div>
                </div>
            </View>
        )
    }
}

export default HeaderWidget;