import React, { Component } from 'react'
import { Text } from 'react-native'

class SecondaryText extends Component {
    render() {
        return (
            <div style={{ marginTop: '5px', textAlign: 'left' }}>
                <Text style={{ color: '#c3c3c3' }}> {this.props.text} </Text>
            </div>
        )
    }
}

export default SecondaryText;