import PGAModel from '../Models/ParticipantGroupAws'
import PGATRansformer from '../Transformers/ParticipantGroupAws'
import API from '../Models/API'

const ParticipantGroupAws = {
	get: (endpoint, callback) => API.get(endpoint, callback),
    getAll: (hash, callback) => {
    	ParticipantGroupAws.get(PGAModel.getAll(hash), (res) => {
    		if (res.error)
    			return callback(res)

    		return PGATRansformer.transform(res, callback)
    	} )
    },

    signOff: (id, callback) => {
        return API.post(PGAModel.signOff(id), {}, callback)
    },

    sendFeedback: (params, callback) => {
		return API.post(PGAModel.sendFeedback(params['id']), params, (res) => {

            let emailParam = {}
                emailParam.email   = "joan.v@sportsinfocus.com.au"
                emailParam.subject = `${params['feedback']} Feedback from Tag Team`
                emailParam.message = `Team ${params['id']} - ${params['feedback_details']}`

            // return API.directPost(PGAModel.postFreshdeskTicket(), emailParam, (_res) => {
                return callback(res)
            // })
        })
    }
}

export default ParticipantGroupAws