import React, { Component } from 'react'
import { browserHistory }   from 'react-router'
import { connect }          from 'react-redux'
import PropTypes            from 'prop-types'
import { toastr }           from 'react-redux-toastr'
import RenderIf             from 'render-if'
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Picker } from 'react-native'

import Participant from '../../Services/Handlers/Participant'
import BoundingBox from './Box'
import LargeButton   from '../../Components/Buttons/Large'

import ParticipantType from '../../Services/Transformers/ParticipantType'


const propTypes = {}

class AddPlayerScene extends Component {
	constructor(props) {
		super(props)
		this.state = {
			type: '1',
			next_scene: ''
		}

		this.navigateToNextScene = this.navigateToNextScene.bind(this)
		this.addNewPlayer = this.addNewPlayer.bind(this)
	}

	componentWillMount() {
		const { state } = this.props.location
		if (state && state.next_scene) {
			this.setState({ next_scene: state.next_scene })
		}
	}

	componentDidMount() {
		const { participant_group_id } = this.props.location.state
		if (!participant_group_id)
			return this.navigateToNextScene()
	}

	navigateToNextScene() {
        const { key } = this.props.params
        const { next_scene } = this.state
        browserHistory.push({
        	pathname: `/${key}/${next_scene}`,
        	state: { 
        		showInstructions: 0,
        		next_scene: 'add'
        	}
        })
    }

    addNewPlayer() {
    	const { dispatch, params, location } = this.props
    	const { state } = location
    	const { type } = this.state

    	let firstName = this.refs.playerFirstName.value
    	let lastName  = this.refs.playerLastName.value

    	if (firstName == '' || lastName == '') {
    		toastr.error("", "Player First Name and Last Name are required.")
    	} else {

	    	dispatch(Participant.create({
	    		hash: params.key,
	    		first_name: firstName,
	    		last_name: `${lastName}${(type == 1) ? '' : `(${type})`}`,
	    		type: (type == 1) ? 1 : 2,
	    		participant_group_id: state.participant_group_id,
	    		currentFace: state.currentBox,
	    		status_id: (state.message) ? 5 : 1,
	    		status: (state.message) ? 'ABSENT' : 'PRESENT'
	    	}, () => {
		    	toastr.success('', `You have created player ${firstName} ${lastName}.`)
		    	this.navigateToNextScene()
	    	}))
    	}
    }

    render() {
    	const { state } = this.props.location
    	const { type } = this.state

    	let types = ParticipantType.transform()

    	return (
    		<div id="add-player-page">
	    		<View style={styles.app}>
	    			<View style={styles.appHeader}>
	    				<View> 
	    					<div style={{ padding: '5px' }}>
								<div style={{ padding: '5px 10px', float: 'left', width: '30%' }} onClick={() => this.navigateToNextScene()}>
									<i className="fa fa-angle-left fa-2x" style={{ fontSize: '14pt', padding: '5px 10px', float: 'left' }}> </i> 
									<span style={{ float: 'left', marginTop: '4px' }}>BACK</span> 
								</div>

								<div className="col-md-8" style={{
									padding: '5px 10px',
								    textAlign: 'center',
								    fontSize: '14pt',
								    float: 'left',
								    width: '40%' }}> New Player </div>
							</div>
	    				</View>
	    			</View>

	    			<View style={styles.appBody}>
	    				<View style={styles.appBodyContainer}>
	    					
	    					<BoundingBox faces={state.faces} singlePhoto={true} height='inherit' width='100%' setCurrentBox={(id) => console.log(id)} isZoomedImage={true} style={{ width: '95%', height: '50%' }} />
	    					{RenderIf( state.message )(
                                <View style={{ height: '100%', marginBottom: '10px' }}>
                                    <div style={{ padding: '15px', color: 'green', backgroundColor: '#60d291', fontSize: '13pt' }}>
                                        <h1>You have tagged everyone</h1>
                                        { state.message }
                                    </div>
                                </View>
                            )}
	    					<div>
			    				<View style={styles.appBodyContent}>
			    					<div>
			    						<input type="text" style={{ padding: '15px', border: 0, borderBottom: '1px solid #cccccc', width: '100%' }} placeholder="First Name" ref="playerFirstName" />
			    					</div>
			    					<div>
			    						<input type="text" style={{ padding: '15px', border: 0, borderBottom: '1px solid #cccccc', width: '100%' }} placeholder="Last Name" ref="playerLastName" />
			    					</div>
			    					<div style={{ padding: '15px', borderBottom: '1px solid #cccccc', textAlign: 'left', color: '#4a4949' }}> 
			    						Player Type 
			    							<Picker
												selectedValue={this.state.type}
												style={{height: 50, width: 100, float: 'right', marginTop: '-15px', border: 0}}
												onValueChange={(itemValue, itemIndex) => {
													if (itemValue != 1) {
														return this.setState({type: itemValue})
													} else {
														return this.setState({type: itemValue})
													}
												} }>
													<Picker.Item label="Player" value="1" />
													{types.map(function(type){
														return ( <Picker.Item label={type} value={type} /> )
													})}
											</Picker>
			    					</div>
		    					</View>
	    					</div>
		    				<div>
		    					<View style={{ flex: 1, marginTop: '5px' }}> 
		    						<LargeButton action={() => this.addNewPlayer()} title="ADD NEW PLAYER" />
		    					</View>
		    				</div>
	    				</View>
	    			</View>
	    		</View>
    		</div>
    	)
    }
}

const styles = StyleSheet.create({
	app: {
		display: 'block',
		flex: 1,
		// width: '100%',
		height: '100vh',
		maxWidth: '700px',
	    marginLeft: 'auto',
	   	marginRight: 'auto'
	},
	appHeader: {
		flex: 0.01,
		justifyContent: 'center',
		backgroundColor: 'white'
	},
	appBody: {
		flex: 1,
        background: 'linear-gradient(0deg,#00486b 0,#00529e)',
        minHeight: '92%'
	},
	appBodyContainer: { 
		margin: '10px',
        height: '100%'
	},
	appBodyContent: { 
		backgroundColor: 'white',
		// width: '95%'
	}
})

AddPlayerScene.propTypes = {
  dispatch: PropTypes.func.isRequired
}

export default connect()(AddPlayerScene)