import React, { Component } from 'react'
import SideMenu             from 'react-native-side-menu'
import Popup                from "reactjs-popup"
import { browserHistory }   from 'react-router'
import { connect }          from 'react-redux'
import PropTypes            from 'prop-types'
import ReactDOM             from 'react-dom'
import { toastr }           from 'react-redux-toastr'
import RenderIf             from 'render-if'
import _                    from 'lodash'
import { View, Text, StyleSheet, TouchableOpacity, TouchableHighlight, Modal, ScrollView, Image, Navigate, Picker } from 'react-native'

import SwipeoutPlayerList from '../Player/swipeout'
import PlayerBoundingBox  from '../Player/Box'
import ExtraLargeButton   from '../../Components/Buttons/ExtraLarge'
import LargeButton        from '../../Components/Buttons/Large'
import PrimaryText        from '../../Components/Texts/Primary'
import SecondaryText      from '../../Components/Texts/Secondary'
import Menu               from '../../Components/Widgets/Drawer'
import HeaderWidget       from '../../Components/Widgets/Header'
import ModalWidget        from '../../Components/Widgets/Popup'

import ParticipantGroupAws   from '../../Services/Handlers/ParticipantGroupAws'
import ParticipantAws        from '../../Services/Handlers/ParticipantAws'
import ParticipantController from '../../Services/Handlers/Participant'
import ParticipantType       from '../../Services/Transformers/ParticipantType'
import TagTeamInitialState   from '../../Services/InitialStates/TagTeam'

import TagUnTagReactionTransformer    from '../../Services/Transformers/TagUnTagReaction'
import ParticipantGroupAwsTransformer from '../../Services/Transformers/ParticipantGroupAws'
import Calendar from '../../Services/Helpers/Calendar'
import TagTeamHelp from '../Help/TagTeam'
import Setting from '../../Services/Handlers/Setting'

const propTypes = {}

class TeamScene extends Component {
    constructor(props) {
        super(props)
        this.state = TagTeamInitialState

        this.navigateToNewPlayerScene = this.navigateToNewPlayerScene.bind(this)
        this.updateParticipantInfo = this.updateParticipantInfo.bind(this)
        this.toggleImagePreview = this.toggleImagePreview.bind(this)
        this.searchParticipants = this.searchParticipants.bind(this)
        this.onOpenImagePreview = this.onOpenImagePreview.bind(this)
        this.toggleInstructions = this.toggleInstructions.bind(this)
        this.toggleEditModal = this.toggleEditModal.bind(this)
        this.markAllAsAbsent = this.markAllAsAbsent.bind(this)
        this.tagParticipant = this.tagParticipant.bind(this)
        this.toggleSideMenu = this.toggleSideMenu.bind(this)
        this.invalidatePage = this.invalidatePage.bind(this)
        this.setParticipant = this.setParticipant.bind(this)
        this.undoLastTagged = this.undoLastTagged.bind(this)
        this.setCurrentBox  = this.setCurrentBox.bind(this)
        this.setFirstName   = this.setFirstName.bind(this)
        this.markArchived   = this.markArchived.bind(this)
        this.setLastName    = this.setLastName.bind(this)
        this.markAbsent     = this.markAbsent.bind(this)
        this.toggleZoomedInPlayer = this.toggleZoomedInPlayer.bind(this)
        this.untagParticipant = this.untagParticipant.bind(this)
        this.handleStatus = this.handleStatus.bind(this)
        this.updateDimensions = this.updateDimensions.bind(this)
    }
    componentWillMount() {
        const { params, dispatch } = this.props
        dispatch(ParticipantGroupAws.getAll(params.key, false, (data) => {
            dispatch(Setting.getByKey('TMUSEPANELNUMBERS', data.event_hash_id));
        }))

        this.setState({_data: false})
    }
    componentWillReceiveProps(props) {
        this.setState({dimensionWidth: window.innerWidth})
    }
    componentDidMount() {
        let bool = !( this.props.location && this.props.location.state && this.props.location.state.showInstructions == 0 )
        this.setState({ isInitial: true, showInstructions: bool })
        window.addEventListener("resize", this.updateDimensions)
    }
    updateDimensions() {
        this.setState({dimensionWidth: window.innerWidth})
    }
    navigateToNewPlayerScene() {
        const { currentBox, participant_group_id } = this.state
        const { params, data } = this.props
        browserHistory.push({
            pathname: `/${params.key}/new`,
            state: {
                faces: data.faces.all_faces,
                currentBox: data.faces.active,
                participant_group_id: (data && data.id) ? data.id : participant_group_id
            }
        })
    }
    toggleInstructions(bool) {
        this.setState({ showInstructions: bool })
    }
    invalidatePage() {
        const { key } = this.props.params
        browserHistory.push({ pathname: `/${key}/invalid` })
    }
    redirectToSignoffScene() {
        const { params, data } = this.props
        const { _data } = this.state

        let currentData = (_data) ? _data : data
        browserHistory.push({ pathname: `/${params.key}/signoff`, state: { data: currentData, finish: true } })
    }
    toggleImagePreview(bool) {
        this.setState({ modalVisible: bool })
    }
    toggleZoomedInPlayer(bool) {
        this.setState( { zoomInPlayer: bool } )
    }
    toggleSideMenu() {
        this.setState({ sideMenu: !this.state.sideMenu })
    }
    searchParticipants(e) {
        this.setState({ filter: e.target.value })
    }
    onOpenImagePreview() {
        const { previewWidth } = this.state
        const { data } = this.props

        if (!data || !data.faces) {
            toastr.error("Data not available. Please try again later")
            this.setState({ modalVisible: false })
        }
    }
    setParticipant(participant) {
        this.setState({ currentFirstName: participant.first_name, currentLastName: participant.last_name, currentParticipant: participant })
        this.toggleEditModal(true)
    }
    toggleEditModal(bool) {
        this.setState({ togglePlayerModal: bool })
    }
    updateParticipantInfo(e) {
        const { params, dispatch, data } = this.props
        const { _data, currentFirstName, currentLastName, currentParticipant, type } = this.state

        let currentData = (_data) ? _data : data
        let players = (currentData.participants && currentData.participants.data) ? currentData.participants.data : []
        let finalLastName = currentLastName;

        if (currentLastName) {
            let splitLastname = currentLastName.split(' ');
            if (type.toLowerCase() !== 'player' && parseInt(type) !== 1) {
                if (splitLastname[1] && splitLastname[1].charAt(0) === '(') {
                    finalLastName = `${splitLastname[0]} (${type})`;
                } else {
                    finalLastName = `${currentLastName} (${type})`;
                }
            } else {
                if (splitLastname[1] && splitLastname[1].charAt(0) === '(') finalLastName = splitLastname[0];
            }
        }

        currentData.participants.data = players.map(function(player) {
            if (currentParticipant.id == player.id) {
                let _player = player
                    _player.first_name = currentFirstName
                    _player.last_name  = finalLastName
                    _player.full_name  = `${currentFirstName} ${finalLastName}`

                return _player
            } else {
                return player
            }
        })

        this.toggleEditModal(false)
        this.setState({ _data: currentData })

        dispatch(ParticipantController.update({
            id: currentParticipant.id,
            participant_group_id: currentParticipant.participant_group_id,
            first_name: currentFirstName,
            last_name: finalLastName,
            status_id: currentParticipant.status_id,
            hash: params.key,
            type: type
        }, () => {}))
    }
    setFirstName(e) {
        this.setState({ currentFirstName: e.target.value })
    }
    setLastName(e) {
        this.setState({ currentLastName: e.target.value })
    }
    markAbsent(player) {
        const { params, dispatch } = this.props
        let face = (player.face && player.face.all_faces) ? player.face.all_faces[0] : null
        dispatch(ParticipantController.markAbsent({ id: player.id, face: face, hash: params.key }))
        this.handleStatus(player, "ABSENT")
    }
    handleStatus(player, status = "ABSENT") {
        const { data } = this.props
        const { _data } = this.state

        let currentData = (_data) ? _data : data

        TagUnTagReactionTransformer.statusChange(player, status, currentData, (newData) => {
            this.setState({ _data: newData })
        })
    }
    markAllAsAbsent() {
        const { data } = this.props
        const { participants } = data

        let _this = this
        let players = (participants && participants.data) ? participants.data : []

        players.map(function(player){
            if (player.status === 'PRESENT' && (!player.face || player.face.length == 0)) {
                _this.markAbsent(player)
            }
        })
    }
    markArchived(player) {
        const { params, dispatch } = this.props
        let face = (player.face && player.face.all_faces) ? player.face.all_faces[0] : null
        dispatch(ParticipantController.markArchived({ id: player.id, face: face, hash: params.key }))

        this.handleStatus(player, "REMOVED FROM TEAM LIST")
    }
    undoLastTagged() {
        const { dispatch, params, data } = this.props
        const { _data } = this.state

        let currentData = (_data) ? _data : data

        TagUnTagReactionTransformer.undo(dispatch, params, currentData, (newData) => {
            this.setState({ _data: newData })
        })
    }
    untagParticipant(faceId) {
        const { params, dispatch, data } = this.props
        const { _data } = this.state
        let currentData = (_data) ? _data : data

        TagUnTagReactionTransformer.unTag(params, dispatch, faceId, currentData, (newData) => {
            this.setState({ _data: newData })
        })
    }
    tagParticipant(player, faceId) {
        const { params, dispatch, data } = this.props
        const { _data } = this.state

        let ctr         = 0
        let nextBox     = 0
        let currentData = (_data) ? _data : data
        let currentBox  = (faceId) ? faceId : currentData.faces.active

        TagUnTagReactionTransformer.tag(params, dispatch, ctr, nextBox, currentData, currentBox, player, faceId, (newData) => {
            this.setState({ _data: newData })
        })
    }
    setCurrentBox(currentBox, currentFile, currentUrl) {
        let currentData = (this.state._data) ? this.state._data : this.props.data
        currentData.faces.active = currentBox

        let stateChange = { currentBox }
        if (currentFile)
            stateChange = { ...stateChange, currentFile }

        if (currentUrl)
            stateChange = { ...stateChange, currentUrl }

        if (currentData)
            stateChange = { ...stateChange, currentData }

        this.setState(stateChange)
    }
    render() {
        const { sideMenu, modalVisible, filter, isInitial, togglePlayerModal, currentFirstName, currentLastName, currentFile, currentUrl, currentBox, showInstructions, zoomInPlayer, _data } = this.state
        const { isFetching, data, invalid } = this.props
        const { participants, id } = data

        let availableFace = true
        let availableParticipants = true

        let currentData = (_data) ? _data : data
        let faces = (currentData.faces) ? currentData.faces.all_faces : []
        let players = (currentData.participants && currentData.participants.data) ? currentData.participants.data : []

        if (invalid || (currentData && currentData.faces && currentData.faces.count === 0) || (currentData && currentData.is_archived)) {
            this.invalidatePage()
            return "Invalid Page"
        } else if (!isFetching && participants && currentData.faces) {

            if (participants.active_with_no_face > 0 && currentData.faces.remaining === 0) {
                availableFace = false
            }

            if ((participants.active_with_no_face === 0 && participants.insert_with_no_face === 0) && currentData.faces.remaining > 0) {
                availableParticipants = false
            }

            if (participants.active_with_no_face == 0 && currentData.faces.remaining == 0) {
                this.redirectToSignoffScene()
                return "SignOff Page"
            }
        }

        let headerTitle = (data && data.team_name) ? data.team_name : ''
        let types = ParticipantType.transform()
        return (
            <View style={{ textAlign: 'center', background: 'linear-gradient(0deg,#00486b 0,#00529e)', minHeight: '100vh' }}>
                <View style={styles.app}>
                    <ScrollView>
                        <View style={styles.appHeader}>
                            <HeaderWidget leftAction={() => this.toggleInstructions(true)} leftIcon="fa fa-info-circle" leftHasBorders={true} rightAction={() => this.toggleImagePreview(true)} rightAction2={() => this.redirectToSignoffScene()}  rightIcon="fa fa-image" rightIcon2="fa fa-users" rightTitle="Preview" rightTitle2="Review" title={(window.innerWidth > 800) ? headerTitle : 'Tag Team'} />
                        </View>

                        <div className="app-preview">
                            <div style={{width: (window.innerWidth < 1300) ? '510px' : '810px', border: '5px solid white'}} id="bigImagePreview">
                                <PlayerBoundingBox previewWidth={(window.innerWidth < 1300) ? 500 : 800} faces={faces} currentFile={currentFile} setCurrentBox={this.setCurrentBox} optBox={(currentData.faces) ? currentData.faces.active : 0} bigImagePreview={true} untagParticipant={this.untagParticipant} tagParticipant={this.tagParticipant} />
                                <img src={currentUrl} style={{width: (window.innerWidth < 1300) ? '500px' : '800px'}}  />
                            </div>

                            <div style={{width: (window.innerWidth < 1300) ? '510px' : '810px'}} className="disclaimer">
                                Disclaimer: Photo and background may not resemble the final photo used in the products.
                            </div>
                        </div>

                        <div className="app-body">
                            <View style={styles.appBody}>
                                <View style={styles.appBodyContainer}>
                                    {RenderIf( !isFetching && availableFace )(
                                        <View style={(window.innerWidth > 800) ? styles.onefiftypx : styles.twohundredpx}>
                                            {RenderIf(faces && faces.length)(
                                                <div style={{ display: 'block', height: '75px' }}>
                                                    {faces.map(_face => {
                                                        let style = (currentData.faces && currentData.faces.active == _face.id) ? { width: '75px', height: '75px', position: 'absolute', display: 'flex' } : { visibility: 'hidden' }
                                                        return (<div style={style} onClick={() => this.toggleZoomedInPlayer(true)}>
                                                            <PlayerBoundingBox currentFile={currentFile}  faces={faces} singlePhoto={true} setCurrentBox={(currentData.faces && currentData.faces.active == _face.id) ? this.setCurrentBox : () => {}} squareFace={ _face.id } optBox={ _face.id } />
                                                        </div>)
                                                    })}
                                                    <div style={{ width: '100%', height: '75px', position: 'absolute' }}> 
                                                        <ExtraLargeButton action={() => this.undoLastTagged()} title="UNDO" width='20%' float='right' marginLeft='5%' description="" />
                                                        <ExtraLargeButton to="/new" action={() => this.navigateToNewPlayerScene()} title="+ Add Player" marginLeft='10%' float='right' width='47%' />
                                                    </div>
                                                </div>
                                            )}

                                            <div style={{ width: '100%', height: '75px', display: 'block' }}>
                                                {RenderIf(window.innerWidth < 800)(<div style={{ color: 'white', textAlign: 'left' }}> <h3 style={{ marginBottom: '0!important' }}>{headerTitle}</h3> </div>)}
                                                <PrimaryText text="Match the face to the player from the list below." />
                                                <SecondaryText text="Slide to edit, remove, or mark absent." />
                                            </div>

                                        </View>
                                    )}

                                    {RenderIf( !isFetching && !availableFace )(
                                        <View style={{ height: '100%', marginBottom: '10px' }}>
                                            <div style={{ padding: '15px', color: 'green', backgroundColor: '#60d291', fontSize: '13pt' }}>
                                                <h1>You're Almost Done</h1>
                                                All faces have now been assigned, but there are still <b>{(participants && participants.active_with_no_face) ? participants.active_with_no_face : 0} participant(s) </b> that need your review.
                                                Please take a moment to mark them as either absent or remove them if they are not part of your team.
                                                If you believe they are in the photo, just mark them absent and you can fix at the next step.
                                            </div>
                                        </View>
                                    )}

                                    <View style={styles.appBodyContent}>
                                        <View>
                                            <input type="text" style={{ padding: '15px', background: 'aliceblue', border: 'white 1px', marginBottom: '5px' }} placeholder="Search Player Name" onKeyUp={this.searchParticipants} />
                                        </View>
                                        <View style={{flex:1}}>
                                            {RenderIf(!isFetching && availableParticipants)(
                                                <SwipeoutPlayerList availableFace={availableFace} players={ players } filter={filter} isFetching={isFetching} editModal={this.setParticipant} markAbsent={this.markAbsent} tagParticipant={this.tagParticipant} currentBox={ (currentData.faces) ? currentData.faces.active : 0 } markArchived={this.markArchived} />
                                            )}

                                            {RenderIf(!isFetching && !availableParticipants)(
                                                <div style={{ height: '100%', padding: '15px', color: 'green', backgroundColor: '#60d291', fontSize: '13pt', marginTop: '5px' }}>
                                                    Please use the <b>Add Player</b> button above to identify the remaining <b>{(currentData && currentData.faces && currentData.faces.remaining) ? currentData.faces.remaining : 0}</b> face(s).
                                                </div>
                                            )}

                                            {RenderIf( !isFetching && !availableFace )( <div style={{ marginTop: '10px' }}> <LargeButton action={() => this.markAllAsAbsent()} title="Mark All As Absent" /> </div> )}
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </div>
                    </ScrollView>
                </View>
                <ModalWidget modalVisible={modalVisible} ref="imagePreview" onOpenModal={this.onOpenImagePreview} toggleModal={this.toggleImagePreview}>
                    <PlayerBoundingBox bigImagePreview={true} previewWidth={window.innerWidth} faces={faces} currentFile={currentFile} currentBox={(currentData.faces) ? currentData.faces.active : 0} setCurrentBox={this.setCurrentBox} optBox={(currentData.faces) ? currentData.faces.active : 0} untagParticipant={this.untagParticipant} tagParticipant={this.tagParticipant} />
                    <img src={currentUrl} style={{width: '100%'}} />
                </ModalWidget>
                <ModalWidget modalVisible={showInstructions} ref="instructions" onOpenModal={() => {}} toggleModal={this.toggleInstructions}>
                    <div style={{ padding: '10px', background: 'linear-gradient(0deg,#00486b 0,#00529e)', color: 'white' }}> How it Works? </div>
                    <div style={{ padding: '10px', height: '80vh' }}> <TagTeamHelp /> </div>
                </ModalWidget>
                <div class="edit-modal">
                    <ModalWidget modalVisible={togglePlayerModal} ref="updatePlayerModal" onOpenModal={() => {}} toggleModal={this.toggleEditModal}>
                        <div style={{width: '100%'}}>
                            <div style={{fontWeight: '500'}}> Edit Player Information </div> <hr />
                            <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                <div>
                                    <span style={{ float: 'left', padding: '10px', width: '30%', textAlign: 'left' }}> First Name: </span>
                                    <input type="text" value="Joan" style={{padding: '10px', width: '70%'}} value={currentFirstName} onChange={this.setFirstName} />
                                </div> <br />
                                <div>
                                    <span style={{ float: 'left', padding: '10px', width: '30%', textAlign: 'left' }}> Last Name: </span>
                                    <input type="text" value="Joan" style={{padding: '10px', width: '70%'}} value={currentLastName} onChange={this.setLastName} />
                                </div> <br />
                                <div >
                                    <span style={{ float: 'left', padding: '10px', width: '30%', textAlign: 'left' }}> Player Type: </span>
                                    <Picker
                                        selectedValue={this.state.type}
                                        style={{height: 50, width: '70%', float: 'right', marginTop: '-15px', border: 0}}
                                        onValueChange={(itemValue, itemIndex) => this.setState({type: itemValue}) }>
                                            <Picker.Item label="Player" value="1" />
                                            {types.map((type) => ( <Picker.Item label={type} value={type} /> ) )}
                                    </Picker>
                                </div>
                            </div> <br /> <hr />
                            <div style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '2%' }}>
                                <button style={{ width: '100%', padding: '10px', backgroundColor: '#33cd5f', color: 'white', border: 'none' }} onClick={this.updateParticipantInfo}> SAVE </button>
                            </div>
                        </div>
                    </ModalWidget>
                </div>
                <ModalWidget modalVisible={zoomInPlayer} ref="zoomedInPlayer" onOpenModal={() => {}} toggleModal={this.toggleZoomedInPlayer}>
                    <PlayerBoundingBox faces={faces} singlePhoto={true} setCurrentBox={() => {}} isZoomedImage={true} zoomed={10}/>
                </ModalWidget>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    app: {
        display: 'block',
        flex: 1
    },
    appHeader: {
        // flex: 1,
        justifyContent: 'center',
        backgroundColor: 'white'
    },
    appBody: {
        flex: 1,
        height: '100%'
    },
    appBodyContainer: {
        margin: '10px',
        height: '100%'
    },
    onefiftypx: { height: '150px' },
    twohundredpx: { height: '180px' },
    appBodyContent: {
        flex: 1
    }
})

TeamScene.propTypes = {
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    isFetching: state.ParticipantGroupAws.isFetching,
    data: state.ParticipantGroupAws.data,
    invalid: state.ParticipantGroupAws.invalid,
})

export default connect(mapStateToProps)(TeamScene)