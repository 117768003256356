import React, { Component } from 'react'
import { Text, View }       from 'react-native'
import RenderIf             from 'render-if'
import Popup                from "reactjs-popup"

class PopupWidget extends Component {
    render() {
        const { modalVisible, ref, onOpenModal, toggleModal } = this.props
        return (
            <Popup 
                open={ modalVisible } 
                position="bottom left" 
                ref={ ref } 
                onOpen={ onOpenModal } 
                onClose={() => toggleModal(false)}>

                <div>
                    <a className="" onClick={() => toggleModal(false)} style={{
                        top:'10px',
                        right: '10px',
                        position: 'absolute',
                        borderRadius: '50%',
                        padding: '5px 10px 5px',
                        color: 'yellow',
                        opacity: '0.65',
                        backgroundColor: 'red',
                        fontWeight: '900'
                    }}> &times; </a>

                    <div> { this.props.children } </div>
                </div>
            </Popup>
        )
    }
}

export default PopupWidget;