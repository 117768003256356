import API from '../Models/API'
import ParticipantModel from '../Models/Participant'

const Participant = {
	transform: (params) => {
		let _participant = ParticipantModel.fields()
			_participant.first_name           = (params.first_name)           ? params.first_name : _participant.first_name
			_participant.last_name            = (params.last_name)            ? params.last_name  : _participant.last_name
			_participant.participant_group_id = (params.participant_group_id) ? params.participant_group_id : _participant.participant_group_id
			_participant.status_id            = (params.status_id)            ? params.status_id  : _participant.status_id
			_participant.type                 = (params.type)                 ? params.type       : _participant.type

		return _participant
	},

	update: (requestBody, callback) => {
		let endpoint = ParticipantModel.update(requestBody.participant_group_id, requestBody.id)
		return API.put(endpoint, Participant.transform(requestBody), callback)
	},

	create: (requestBody, callback) => {
		let endpoint = ParticipantModel.create(requestBody.participant_group_id)
		return API.post(endpoint, Participant.transform(requestBody), callback)
	},

	markAbsent: (id, callback) => {
		let endpoint = ParticipantModel.absent(id)
		return API.post(endpoint, {}, callback)
	},

	markArchived: (id, callback) => {
		let endpoint = ParticipantModel.archive(id)
		return API.post(endpoint, {}, callback)
	},

	markPresent: (id, callback) => {
		let endpoint = ParticipantModel.present(id)
		return API.post(endpoint, {}, callback)
	}
}

export default Participant