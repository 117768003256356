import React, { Component } from 'react'
import RenderIf             from 'render-if'
import { browserHistory }   from 'react-router'
import Popup                from 'reactjs-popup'
import { connect }          from 'react-redux'
import PropTypes            from 'prop-types'
import _                    from 'lodash'
import { View, Text, StyleSheet, ScrollView, Picker } from 'react-native'

import PrimaryText        from '../../Components/Texts/Primary'
import SecondaryText      from '../../Components/Texts/Secondary'
import HeaderWidget       from '../../Components/Widgets/Header'
import ModalWidget        from '../../Components/Widgets/Popup'
import BoundingBox        from '../Player/Box'
import PlayerList         from '../Player/List'
import SwipeOutList       from '../../Components/Lists/SwipeOut'

import ParticipantController from '../../Services/Handlers/Participant'
import ParticipantGroupAws   from '../../Services/Handlers/ParticipantGroupAws'
import ParticipantAws        from '../../Services/Handlers/ParticipantAws'
import ParticipantType       from '../../Services/Transformers/ParticipantType'
import SignoffInitialState   from '../../Services/InitialStates/Signoff'
import LargeButton           from '../../Components/Buttons/Large'
import LargeOrangeButton     from '../../Components/Buttons/Orange/Large'

import ParticipantGroupAwsTransformer from '../../Services/Transformers/ParticipantGroupAws'
import Setting from '../../Services/Handlers/Setting'

const propTypes = {}
class TeamSignOffScene extends Component {
	constructor(props) {
		super(props)
		this.state = SignoffInitialState

		this.navigateToPreviewsScene = this.navigateToPreviewsScene.bind(this)
		this.toggleImagePreview = this.toggleImagePreview.bind(this)
		this.setCurrentParticipant = this.setCurrentParticipant.bind(this)
		this.handleClick = this.handleClick.bind(this)
		this.toggleEditModal = this.toggleEditModal.bind(this)
		this.signOffTagTeam = this.signOffTagTeam.bind(this)
		this.markArchived = this.markArchived.bind(this)
		this.markAbsent = this.markAbsent.bind(this)
		this.unTagPlayer = this.unTagPlayer.bind(this)
		this.markPresent = this.markPresent.bind(this)
		this.setFirstName = this.setFirstName.bind(this)
		this.setLastName = this.setLastName.bind(this)
		this.updateParticipantInfo = this.updateParticipantInfo.bind(this)
		this.navigateToFeedbackScene = this.navigateToFeedbackScene.bind(this)
		this.navigateToNewPlayerScene = this.navigateToNewPlayerScene.bind(this)
		this.handleStatus = this.handleStatus.bind(this)
	}

	componentWillMount() {
        const { params, dispatch, location } = this.props
        const { state } = location

        if (state && state.data) {
            let statusOrder = [1, 3, 5, 0]
            let players = _.sortBy(state.data.participants.data, function(o) { return statusOrder.indexOf(o.status_id); })
            state.data.participants.data = players
        }

        this.setState({ keepLoading: (state && state.finish), _data: (state) ? state.data : null })
        dispatch(ParticipantGroupAws.getAll(params.key, false, (data) => {
            dispatch(Setting.getByKey('TMUSEPANELNUMBERS', data.event_hash_id));
        }))
    }

    componentWillReceiveProps(props) {
    	const { data, params, dispatch, location } = this.props
        const { state } = location

        if (state && state.data) {
            let statusOrder = [1, 3, 5, 0]
            let players = _.sortBy(state.data.participants.data, function(o) { return statusOrder.indexOf(o.status_id); })
            state.data.participants.data = players
        }

        this.setState({ keepLoading: (state && state.finish), _data: (state) ? state.data : null })
    }

	navigateToPreviewsScene() {
        const { key } = this.props.params
        browserHistory.push({
        	pathname: `/${key}`,
        	state: { showInstructions: 0 }
        })
    }

    navigateToFeedbackScene() {
    	const { params, data } = this.props
    	browserHistory.push({
            pathname: `/${params.key}/feedback`,
            state: { participant_group_id: (data && data.id) ? data.id : 0 }
        })
    }

    signOffTagTeam() {
    	const { params, dispatch, data } = this.props
    	let participant = (data.participants && data.participants.data) ? data.participants.data[0] : {}
        dispatch(ParticipantGroupAws.signOff(participant.participant_group_id, this.navigateToFeedbackScene))
    }

    toggleImagePreview() {
        this.setState({ modalVisible: !this.state.modalVisible })
    }

    setCurrentParticipant(player) {
    	this.setState({ currentFirstName: player.first_name, currentLastName: player.last_name, currentParticipant: player })
    }

    handleClick() { }

    toggleEditModal(bool) {
    	this.setState({ modalVisible: bool })
    }

    markArchived(player) {
    	const { params, dispatch } = this.props

        let face = (player.face && player.face.all_faces) ? player.face.all_faces[0] : null
        dispatch(ParticipantController.markArchived({ id: player.id, face: face, hash: params.key }))
        return this.handleStatus(player, "REMOVED FROM TEAM LIST")
    }

    markAbsent(player) {
    	const { params, dispatch } = this.props

        let face = (player.face && player.face.all_faces) ? player.face.all_faces[0] : null
        dispatch(ParticipantController.markAbsent({ id: player.id, face: face, hash: params.key }))
        return this.handleStatus(player, "ABSENT")
    }

    markPresent(player) {
    	const { params, dispatch } = this.props
        dispatch(ParticipantController.markPresent({ id: player.id, hash: params.key }))
        return this.handleStatus(player, "PRESENT")
    }

    handleStatus(player, status = "ABSENT") {
    	const { data } = this.props
    	const { _data } = this.state

        let currentData = (_data) ? _data : data
        let lastFace    = currentData.last_face

        if (status == 'PRESENT') {
			currentData.participants.active_with_no_face += 1
		} else {
			currentData.participants.active_with_no_face -= 1
		}

        currentData.participants.data = currentData.participants.data.map(function(participant) {
        	if (player.id == participant.id) {
        		currentData.faces.all_faces = currentData.faces.all_faces.map(function(face){
	                if (face.participant_id == player.id) {
        				currentData.faces.remaining += 1
	                    let newFace = face
	                        newFace.participant_id = null
	                        newFace.is_tagged = false
	                        newFace.is_archived = false

	                    return newFace
	                }

	                return face
	            })

        		let _participant = participant
                    _participant.face = null
                    _participant.status = status

                return _participant
        	}

        	return participant
        })

        this.setState({ _data: currentData, untaggedCount: (this.state.untaggedCount + 1), keepLoading: false })
    }

    unTagPlayer(_face) {
        const { params, dispatch, data } = this.props
        const { _data } = this.state

        dispatch( ParticipantAws.unAssign({ id: _face.id, hash: params.key }) )

        let currentData = (_data) ? _data : data
        let lastFace    = currentData.last_face

        currentData.faces.all_faces = currentData.faces.all_faces.map(function(face){
            if (face.id == _face.id) {
                currentData.participants.data = currentData.participants.data.map(function(participant) {
                    if ( _face.participant_id == participant.id ) {
                        currentData.participants.active_with_no_face += 1
                        currentData.faces.remaining += 1

                        let _participant = participant
                            _participant.face = null

                        return _participant
                    }

                    return participant
                })

                let newFace = face
                    newFace.participant_id = null
                    newFace.is_tagged = false
                    newFace.is_archived = false

                return newFace

            }

            return face
        })

        this.setState({ _data: currentData, untaggedCount: (this.state.untaggedCount + 1), keepLoading: false })
    }

	setFirstName(e) {
		this.setState({ currentFirstName: e.target.value })
	}

	setLastName(e) {
		this.setState({ currentLastName: e.target.value })
	}

	updateParticipantInfo() {
        const { params, dispatch, data } = this.props
        const { _data, currentFirstName, currentLastName, currentParticipant, type } = this.state

        let currentData = (_data) ? _data : data
        let players = (currentData.participants && currentData.participants.data) ? currentData.participants.data : []
        let finalLastName = currentLastName;

        if (currentLastName) {
            let splitLastname = currentLastName.split(' ');
            if (type.toLowerCase() !== 'player' && parseInt(type) !== 1) {
                if (splitLastname[1] && splitLastname[1].charAt(0) === '(') {
                    finalLastName = `${splitLastname[0]} (${type})`;
                } else {
                    finalLastName = `${currentLastName} (${type})`;
                }
            } else {
                if (splitLastname[1] && splitLastname[1].charAt(0) === '(') finalLastName = splitLastname[0];
            }
        }

        currentData.participants.data = players.map(function(player) {
            if (currentParticipant.id == player.id) {
                let _player = player
                    _player.first_name = currentFirstName
                    _player.last_name  = finalLastName
                    _player.full_name  = `${currentFirstName} ${finalLastName}`

                return _player
            } else {
                return player
            }
        })

        this.toggleEditModal(false)
        this.setState({ _data: currentData })

        dispatch(ParticipantController.update({
            id: currentParticipant.id,
            participant_group_id: currentParticipant.participant_group_id,
            first_name: currentFirstName,
            last_name: finalLastName,
            status_id: currentParticipant.status_id,
            hash: params.key,
            type: type
        }, () => {}))
	}

	navigateToNewPlayerScene() {
        const { currentBox, participant_group_id } = this.state
        const { params, data } = this.props

        let currentData = (this.state._data) ? this.state._data : data

        browserHistory.push({
            pathname: `/${params.key}/new`,
            state: {
                faces: data.faces.all_faces,
                participant_group_id: (data && data.id) ? data.id : participant_group_id,
                next_scene: 'signoff',
                message: (currentData.faces && currentData.faces.remaining == 0) ? "All faces are tagged. Newly added player will be marked absent" : null
            }
        })
    }

    toggleImagePreview(bool) {
    	this.setState({ modalPreviewVisible: bool })
    }

    setCurrentBox(currentBox, currentFile, currentUrl) {
        this.setState({ currentFile: currentFile, currentUrl: currentUrl})
    }

    render() {
    	const { modalVisible, currentFirstName, currentLastName, keepLoading, modalPreviewVisible, currentFile, currentUrl } = this.state
    	const { data, dispatch, params, location } = this.props
    	const { _data } = this.state

    	let currentData = (_data) ? _data : data

    	let btns    = []
    	let _this   = this
        let faces   = (currentData.faces) ? currentData.faces.all_faces : null
    	let players = (currentData && currentData.participants && currentData.participants.data) ? currentData.participants.data : []
    	let _untaggedCount   = (currentData && currentData.faces && currentData.faces.remaining) ? currentData.faces.remaining : 0
    	let _untaggedPlayers = (currentData && currentData.participants && currentData.participants.active_with_no_face) ? currentData.participants.active_with_no_face : 0

    	let firstFile = (faces && faces[0]) ? faces[0].file : ''
    	let firstUrl = (faces && faces[0]) ? faces[0].url : ''
        let types = ParticipantType.transform()

    	return (
    		<View style={{ textAlign: 'center', background: 'linear-gradient(0deg,#00486b 0,#00529e)', minHeight: '100vh'}}>
	    		<View style={styles.app}>
	    			<View style={styles.appHeader}>
                        <HeaderWidget leftAction={this.navigateToPreviewsScene} leftTitle="Back" leftIcon="fa fa-angle-left fa-2x" title="Review Team" rightAction={() => this.toggleImagePreview(true)} rightIcon="fa fa-image" rightTitle="Preview" />
                    </View>

	    			<View style={styles.appBody}>
	    				<View style={styles.appBodyContainer}>
	    					<div>
	    						<View style={styles.appBodyHeader}>
	                                <div style={{float: 'right'}}>
	                                	<LargeButton action={() => this.navigateToNewPlayerScene()} title="+ ADD PLAYER" />
	                                </div>
		    						<PrimaryText text="You're Almost Done.." />
	                                <SecondaryText text="Please double check all players' name and spelling one last time before submitting." />
		    					</View>

		    					<View style={styles.appBodyContent}>
		    						<View style={{ height: '100%', backgroundColor: 'white' }}>
                                        <PlayerList
                                            players={players}
                                            markArchived={this.markArchived}
                                            unTagPlayer={this.unTagPlayer}
                                            toggleEditModal={this.toggleEditModal}
                                            markPresent={this.markPresent}
                                            markAbsent={this.markAbsent}
                                            setCurrentParticipant={this.setCurrentParticipant}
                                            handleClick={this.handleClick} />
		    						</View>
			    					<div>
				    					<View style={{ flex: 1, width: '95vw', marginTop: '5px' }}>
				    						{RenderIf(_untaggedCount === 0 && _untaggedPlayers === 0)( <LargeButton action={() => this.signOffTagTeam()} title="SUBMIT" /> ) }
				    						{RenderIf(_untaggedCount > 0 || _untaggedPlayers > 0)( <LargeOrangeButton action={() => this.navigateToPreviewsScene()} title="CONTINUE" /> ) }
				    					</View>
				    				</div>
		    					</View>
	    					</div>
	    				</View>
	    			</View>
	    		</View>
                <ModalWidget modalVisible={modalPreviewVisible} ref="imagePreview" onOpenModal={this.onOpenImagePreview} toggleModal={this.toggleImagePreview}>
                    <BoundingBox bigImagePreview={true} previewWidth={window.innerWidth} faces={faces} currentFile={(currentFile) ? currentFile : firstFile} currentBox={(currentData.faces) ? currentData.faces.active : 0} setCurrentBox={this.setCurrentBox} optBox={(currentData.faces) ? currentData.faces.active : 0}  />
                    <img src={(currentUrl) ? currentUrl : firstUrl} style={{width: '100%'}} />
                </ModalWidget>

                <div className="edit-modal">
	                <ModalWidget modalVisible={modalVisible} ref="updatePlayerModal" onOpenModal={() => {}} toggleModal={this.toggleEditModal}>
	                    <div style={{width: '100%'}}>
                            <div style={{fontWeight: '500'}}> Edit Player Information </div> <hr />
                            <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                <div>
                                    <span style={{ float: 'left', padding: '10px', width: '30%', textAlign: 'left' }}> First Name: </span>
                                    <input type="text" value="Joan" style={{padding: '10px', width: '70%'}} value={currentFirstName} onChange={this.setFirstName} />
                                </div> <br />
                                <div>
                                    <span style={{ float: 'left', padding: '10px', width: '30%', textAlign: 'left' }}> Last Name: </span>
                                    <input type="text" value="Joan" style={{padding: '10px', width: '70%'}} value={currentLastName} onChange={this.setLastName} />
                                </div> <br />
                                <div>
                                    <span style={{ float: 'left', padding: '10px', width: '30%', textAlign: 'left' }}> Player Type: </span>
                                    <Picker
                                        selectedValue={this.state.type}
                                        style={{height: 50, width: '70%', float: 'right', marginTop: '-15px', border: 0}}
                                        onValueChange={(itemValue, itemIndex) => this.setState({type: itemValue}) }>
                                            <Picker.Item label="Player" value="1" />
                                            {types.map((type) => ( <Picker.Item label={type} value={type} /> ) )}
                                    </Picker>
                                </div>
                            </div> <br /> <hr />
                            <div style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '2%' }}>
                                <button style={{ width: '100%', padding: '10px', backgroundColor: '#33cd5f', color: 'white', border: 'none' }} onClick={this.updateParticipantInfo}> SAVE </button>
                            </div>
                        </div>
	                </ModalWidget>
                </div>
    		</View>
    	)
    }
}

const styles = StyleSheet.create({
	app: {
		display: 'block',
		flex: 1
	},
	appHeader: {
        flex: 1,
        justifyContent: 'center',
        backgroundColor: 'white'
    },
	appBodyContainer: {
        margin: '10px',
        height: '100%'
    },
	appBodyHeader: {
		flex: 1,
		width: '95vw',
		height: '100px',
		display: 'inline-block'
	},
	appBodyContent: {
		// backgroundColor: 'white',
		display: 'inline-block',
		width: '95vw',
		flex: 1
	},
    appBody: {
        flex: 1,
        height: 'inherit'
    },
})

TeamSignOffScene.propTypes = {
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    isFetching: state.ParticipantGroupAws.isFetching,
    data: state.ParticipantGroupAws.data,
    invalid: state.ParticipantGroupAws.invalid
})

export default connect(mapStateToProps)(TeamSignOffScene)