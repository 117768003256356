const SignOffInitialState = {
    modalVisible       : false,
    untaggedCount      : 0,
    currentFirstName   : '',
    currentLastName    : '',
    currentParticipant : '',
    keepLoading        : false,
    _data              : null,
    modalPreviewVisible: false,
    currentFile        : null,
    currentUrl         : null,
    type               : '1'
}

export default SignOffInitialState