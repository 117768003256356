import React, { Component } from 'react'
import { Text } from 'react-native'

class PrimaryText extends Component {
    render() {
        return (
            <div style={{ marginTop: (window.innerWidth > 800) ? '15px' : '5px', textAlign: 'left' }}>
                <Text style={{ color: 'white' }}> {this.props.text} </Text>
            </div>
        )
    }
}

export default PrimaryText;